import { useState } from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";

import {
  List,
  Table,
  useTable,
  getDefaultSortOrder,
  // FilterDropdown,
  // Select,
  // useSelect,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  // ImageField,
  Form,
  // Radio,
  // Tag,
  ShowButton,
} from "@pankod/refine-antd";

import { IPost } from "interfaces";

// import { API_URL } from "../../constants";

export const CareerList: React.FC<IResourceComponentsProps> = () => {
  //   const [locale, setLocale] = useState("en");
  const [publicationState, setPublicationState] = useState("live");

  const { tableProps, sorter } = useTable<IPost>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    metaData: {
      populate: ["values"],
      //   locale,
      publicationState,
    },
  });

  //   const { selectProps } = useSelect({
  //     resource: "categories",
  //     optionLabel: "title",
  //     optionValue: "id",
  //     metaData: { locale },
  //   });

  return (
    <List>
      <Form
        layout="inline"
        initialValues={{
          publicationState,
        }}
      ></Form>
      <br />
      <Table
        {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
        }}
      >
        <Table.Column
          key="designation"
          dataIndex="designation"
          title="Designation"
          defaultSortOrder={getDefaultSortOrder("designation", sorter)}
          sorter={{ multiple: 2 }}
        />

        <Table.Column
          key="jobType"
          dataIndex="jobType"
          title="Job Type"
          defaultSortOrder={getDefaultSortOrder("jobType", sorter)}
          sorter={{ multiple: 2 }}
        />

        <Table.Column
          dataIndex="createdAt"
          title="Created At"
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter={{ multiple: 1 }}
        />

        <Table.Column<{ id: string }>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
              <ShowButton size="small" recordItemId={record.id} hideText />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
