import React from "react";
import { Refine, AuthProvider } from "@pankod/refine-core";
import {
  notificationProvider,
  Layout,
  ErrorComponent,
  Icons,
} from "@pankod/refine-antd";
import { DataProvider, AuthHelper } from "@pankod/refine-strapi-v4";
import routerProvider from "@pankod/refine-react-router-v6";
import axios from "axios";
import "@pankod/refine-antd/dist/styles.min.css";

// import { TOKEN_KEY, API_URL } from "./constants";
// import { TOKEN_KEY } from "./constants";
import { serverUrl, tokenKey } from "server";

import {
  SystemCreate,
  SystemEdit,
  SystemList,
  SystemShow,
} from "pages/systems";
import {
  ProductCreate,
  ProductEdit,
  ProductList,
  ProductShow,
} from "pages/products";
import {
  ComponentCreate,
  ComponentEdit,
  ComponentList,
  ComponentShow,
} from "pages/component-types";
import {
  FilterCreate,
  FilterEdit,
  FilterList,
  FilterShow,
} from "pages/filters";
import { Title } from "components/title";
import { Login } from "pages/auth/Login";
import {
  CareerCreate,
  CareerEdit,
  CareerList,
  CareerShow,
} from "pages/careers";
import {
  SoftwareCreate,
  SoftwareEdit,
  SoftwareList,
  SoftwareShow,
} from "pages/softwares";
// import { BlogCreate, BlogEdit, BlogList, BlogShow } from "pages/blogs";

const App: React.FC = () => {
  const axiosInstance = axios.create();
  const strapiAuthHelper = AuthHelper(serverUrl + "/api");

  const authProvider: AuthProvider = {
    login: async ({ username, password }) => {
      const { data, status } = await strapiAuthHelper.login(username, password);
      if (status === 200) {
        localStorage.setItem(tokenKey, data.jwt);

        // set header axios instance
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.jwt}`;

        return Promise.resolve();
      }
      return Promise.reject();
    },
    logout: () => {
      localStorage.removeItem(tokenKey);
      return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
      const token = localStorage.getItem(tokenKey);
      if (token) {
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token}`;
        return Promise.resolve();
      }

      return Promise.reject();
    },
    getPermissions: () => Promise.resolve(),
    getUserIdentity: async () => {
      const token = localStorage.getItem(tokenKey);
      if (!token) {
        return Promise.reject();
      }

      const { data, status } = await strapiAuthHelper.me(token, {
        metaData: {
          populate: ["role"],
        },
      });

      if (status === 200) {
        const { id, username, email } = data;
        return Promise.resolve({
          id,
          username,
          email,
        });
      }

      return Promise.reject();
    },
  };

  return (
    <Refine
      authProvider={authProvider}
      dataProvider={DataProvider(
        serverUrl + "/api"
        // axiosInstance
      )}
      routerProvider={routerProvider}
      Title={Title}
      resources={[
        {
          name: "systems",
          list: SystemList,
          create: SystemCreate,
          edit: SystemEdit,
          show: SystemShow,
          icon: <Icons.ShopOutlined />,
        },
        {
          name: "filters",
          list: FilterList,
          create: FilterCreate,
          edit: FilterEdit,
          show: FilterShow,
          icon: <Icons.FilterOutlined />,
        },
        {
          name: "component-types",
          list: ComponentList,
          create: ComponentCreate,
          edit: ComponentEdit,
          show: ComponentShow,
        },

        {
          name: "products",
          list: ProductList,
          create: ProductCreate,
          edit: ProductEdit,
          show: ProductShow,
          icon: <Icons.CameraOutlined />,
        },
        {
          name: "softwares",
          list: SoftwareList,
          create: SoftwareCreate,
          edit: SoftwareEdit,
          show: SoftwareShow,
          icon: <Icons.ProjectOutlined />,
        },
        {
          name: "careers",
          list: CareerList,
          create: CareerCreate,
          edit: CareerEdit,
          show: CareerShow,
          icon: <Icons.DesktopOutlined />,
        },
        // {
        //   name: "blogs",
        //   list: BlogList,
        //   create: BlogCreate,
        //   edit: BlogEdit,
        //   show: BlogShow,
        //   icon: <Icons.BookOutlined />,
        // },

        // {
        //   name: "users",
        //   list: UsersList,
        // },
      ]}
      notificationProvider={notificationProvider}
      Layout={Layout}
      LoginPage={Login}
      // ReadyPage={ReadyPage}
      catchAll={<ErrorComponent />}
    />
  );
};

export default App;
