import React, { useState } from "react";
import { IResourceComponentsProps, useApiUrl } from "@pankod/refine-core";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Create,
  Form,
  Input,
  Select,
  useForm,
  useSelect,
  Upload,
  Radio,
  Button,
  Space,
  message,
  UploadFile,
} from "@pankod/refine-antd";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import {
  useStrapiUpload,
  mediaUploadMapper,
  getValueProps,
} from "@pankod/refine-strapi-v4";
// import { TOKEN_KEY } from "../../constants";
import { IPost } from "interfaces";
import { serverUrl } from "server";

export const SystemCreate: React.FC<IResourceComponentsProps> = () => {
  const [mediaOne, setMediaOne] = useState(null);
  const [mediaTwo, setMediaTwo] = useState(null);
  const [mediaThree, setMediaThree] = useState(null);
  const [mediaFour, setMediaFour] = useState(null);
  const [brochure, setBrochure] = useState(null);

  const [highlightImage, setHighlightImage] = useState(null);
  const [newScope, setNewScope] = useState<any[]>([]);
  const [thumbnail, setThumbnail] = useState(null);
  const API_URL = useApiUrl();
  //   const [locale, setLocale] = useState("en");

  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const { formProps, saveButtonProps } = useForm<IPost>();

  const { selectProps } = useSelect({
    resource: "filters",
    // metaData: { filters },
    optionLabel: "name",
    optionValue: "id",
  });

  //   const { options } = useSelect({
  //     resource: "filters",
  //     // optionLabel: "title",
  //     // optionValue: "id",
  //   });

  const { ...uploadProps } = useStrapiUpload({
    maxCount: 1,
  });

  const validateFileType = (
    { type, name }: UploadFile,
    allowedTypes?: string
  ) => {
    if (!allowedTypes) {
      return true;
    }

    if (type) {
      return allowedTypes.includes(type);
    }
  };

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        scrollToFirstError={true}
        onFinishFailed={() => {
          message.error("Submit failed!, Please check the input fields");
        }}
        onFinish={async (values: any) => {
          const media = {
            mediaone: mediaOne,
            mediatwo: mediaTwo,
            mediathree: mediaThree,
            mediafour: mediaFour,
          };
          values["thumbnail"] = thumbnail;
          values["mainbanner"]["brochure"] = brochure;
          values["mainbanner"]["media"] = media;
          values["highlights"]["image"] = highlightImage;
          let slugString = values.pageTitle.replace(/[^a-zA-Z0-9 ]/g, "");

          values["slug"] = slugString.toLowerCase().split(" ").join("-");

          let newScopeArray: any[] = [];
          values?.scope?.scope?.map((value: any) => {
            newScopeArray.push({
              title: value?.title,
              image: value?.image?.file?.response[0]?.id,
            });
          });

          values["scope"]["scope"] = newScopeArray;

          return (
            formProps.onFinish && formProps.onFinish(mediaUploadMapper(values))
          );
        }}
      >
        <Form.Item
          label="Page title"
          name="pageTitle"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Order"
          name="order"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item label="Thumbnail">
          <Form.Item
            name="thumbnail"
            valuePropName="fileList"
            getValueProps={(data) => getValueProps(data, serverUrl)}
            noStyle
          >
            <Upload.Dragger
              name="files"
              action={`${serverUrl}/api/upload`}
              listType="picture"
              multiple
              showUploadList={{ showRemoveIcon: false }}
              {...uploadProps}
              onChange={(info: any) => {
                if (info.file.status === "done") {
                  setThumbnail(info?.file?.response[0]?.id);
                }
              }}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        <Form.Item
          label="Card Description"
          name="description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item>

        <Form.Item
          label="System Category"
          name="systemCategory"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            <option value="Security">Security</option>
            <option value="Commercial">Commercial</option>
          </Select>
        </Form.Item>

        {/* ----------------- main banner --------------------- */}
        <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>Main Banner</h1>

        <Form.Item
          label="Heading"
          name={["mainbanner", "heading"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Description"
          name={["mainbanner", "description"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item>

        <Form.Item label="Media One">
          <Form.Item
            name="mediaone"
            valuePropName="fileList"
            getValueProps={(data) => getValueProps(data, serverUrl)}
            noStyle
          >
            <Upload.Dragger
              name="files"
              action={`${serverUrl}/api/upload`}
              listType="picture"
              multiple
              showUploadList={{ showRemoveIcon: false }}
              {...uploadProps}
              onChange={(info: any) => {
                if (info.file.status === "done") {
                  setMediaOne(info?.file?.response[0]?.id);
                }
              }}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        <Form.Item label="Media Two">
          <Form.Item
            name="mediatwo"
            valuePropName="fileList"
            getValueProps={(data) => getValueProps(data, serverUrl)}
            noStyle
          >
            <Upload.Dragger
              name="files"
              action={`${serverUrl}/api/upload`}
              listType="picture"
              multiple
              showUploadList={{ showRemoveIcon: false }}
              {...uploadProps}
              onChange={(info: any) => {
                if (info.file.status === "done") {
                  setMediaTwo(info?.file?.response[0]?.id);
                }
              }}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        <Form.Item label="Media Three">
          <Form.Item
            name="mediathree"
            valuePropName="fileList"
            getValueProps={(data) => getValueProps(data, serverUrl)}
            noStyle
          >
            <Upload.Dragger
              name="files"
              action={`${serverUrl}/api/upload`}
              listType="picture"
              multiple
              showUploadList={{ showRemoveIcon: false }}
              {...uploadProps}
              onChange={(info: any) => {
                if (info.file.status === "done") {
                  setMediaThree(info?.file?.response[0]?.id);
                }
              }}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        {/* <Form.Item label="Media Four">
          <Form.Item
            name="mediafour"
            valuePropName="fileList"
            getValueProps={(data) => getValueProps(data, serverUrl)}
            noStyle
          >
            <Upload.Dragger
              name="files"
              action={`${serverUrl}/api/upload`}
              listType="picture"
              multiple
              {...uploadProps}
              onChange={(info: any) => {
                if (info.file.status === "done") {
                  setMediaFour(info?.file?.response[0]?.id);
                }
              }}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item> */}

        <Form.Item label="Brochure">
          <Form.Item
            name="brochure"
            valuePropName="fileList"
            getValueProps={(data) => getValueProps(data, API_URL)}
            noStyle
          >
            <Upload.Dragger
              name="files"
              action={`${API_URL}/upload`}
              listType="picture"
              multiple
              {...uploadProps}
              beforeUpload={(file) => {
                const isPDF = file.type === "application/pdf";
                if (!isPDF) {
                  message.error(`${file.name} is not a pdf file`);
                }
                return isPDF || Upload.LIST_IGNORE;
              }}
              onChange={(info: any) => {
                console.log("info file", info?.file);
                if (info.file.status === "done") {
                  setBrochure(info?.file?.response[0]?.id);
                }
              }}
            >
              <p className="ant-upload-text">
                Drag & drop a PDF file in this area
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        {/* ------------------------------------------------------- */}
        {/* --------------- specifications ------------------------ */}

        <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
          Specification section
        </h1>

        <Form.Item
          label="Heading"
          name={["specifications", "heading"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.List name={["specifications", "specification"]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={
                    {
                      // display: "flex",
                      // marginBottom: 8,
                    }
                  }
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "title"]}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input placeholder="Title" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "description"]}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <ReactMde
                      selectedTab={selectedTab}
                      onTabChange={setSelectedTab}
                      generateMarkdownPreview={(markdown) =>
                        Promise.resolve(
                          <ReactMarkdown>{markdown}</ReactMarkdown>
                        )
                      }
                    />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Specification
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        {/* ------------------------------------------- */}
        {/* ------------- highlights ------------------ */}

        <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
          Highlights section
        </h1>

        <Form.Item
          label="Title"
          name={["highlights", "title"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Description"
          name={["highlights", "description"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item>

        <Form.Item label="Image">
          <Form.Item
            name="image"
            valuePropName="fileList"
            getValueProps={(data) => getValueProps(data, serverUrl)}
            noStyle
          >
            <Upload.Dragger
              name="files"
              action={`${serverUrl}/api/upload`}
              listType="picture"
              multiple
              {...uploadProps}
              onChange={(info: any) => {
                if (info.file.status === "done") {
                  setHighlightImage(info?.file?.response[0]?.id);
                }
              }}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        {/* --------------------------------------------- */}
        {/* ----------------- scope --------------------- */}

        <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>Scope section</h1>

        <Form.Item
          label="Heading"
          name={["scope", "heading"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.List name={["scope", "scope"]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={
                    {
                      // display: "flex",
                      // marginBottom: 8,
                    }
                  }
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "title"]}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input placeholder="Title" />
                  </Form.Item>
                  <Form.Item style={{ width: "400px" }}>
                    <Form.Item
                      name={[name, "image"]}
                      valuePropName="fileList"
                      getValueProps={(data) => getValueProps(data, serverUrl)}
                      noStyle
                    >
                      <Upload.Dragger
                        name="files"
                        action={`${serverUrl}/api/upload`}
                        listType="picture"
                        multiple
                        {...uploadProps}
                      >
                        <p className="ant-upload-text">Drag & drop an image</p>
                      </Upload.Dragger>
                    </Form.Item>
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Scope
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        {/* ------------------------------------------- */}
      </Form>
    </Create>
  );
};
