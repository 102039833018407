// const isDev = process.env.NODE_ENV === "development";
const isDev = false;

const host = "localhost";
export const serverUrl = isDev
  ? `http://${host}:1337`
  : `https://api.lucidimaging.in`;

export const cdnUrl = isDev
  ? `http://${host}:1337`
  : `https://api.lucidimaging.in`;
export const tokenKey = "lucid-jwt-token";
