import { useShow, useOne, useApiUrl } from "@pankod/refine-core";
import { Show, Typography, Tag, Image, Row, Col } from "@pankod/refine-antd";

import { IPost, ICategory } from "interfaces";
import { cdnUrl } from "server";
// import { API_URL } from "constants";
import ReactPlayer from "react-player/youtube";
import ReactMarkdown from "react-markdown";

const { Title, Text } = Typography;

export const SoftwareShow = () => {
  const API_URL = useApiUrl();

  const { queryResult } = useShow({
    metaData: {
      populate: "deep",
    },
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { data: categoryData } = useOne({
    resource: "categories",
    id: record?.category?.id || "",
    queryOptions: {
      enabled: !!record?.category?.id,
    },
  });

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Page Title</Title>
      <Text>{record?.pageTitle}</Text>

      {record?.thumbnail && (
        <div>
          <Title level={5}>Thumbnail</Title>
          <Image src={cdnUrl + record?.thumbnail?.url} width={100} />
        </div>
      )}

      <Title level={5}>Card Description</Title>
      {/* <Text>{record?.description}</Text> */}
      <ReactMarkdown>{record?.description}</ReactMarkdown>
      {/* ----------------- main banner --------------------- */}

      <h1
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          //   marginTop: "20px",
          textDecoration: "underline",
        }}
      >
        Main Banner
      </h1>

      <Title level={5}>Heading</Title>
      <Text>{record?.mainbanner?.heading}</Text>

      <Title level={5}>Description</Title>
      <Text>
        {/* <div
          dangerouslySetInnerHTML={{ __html: record?.mainbanner?.description }}
        /> */}
        <ReactMarkdown>{record?.mainbanner?.description}</ReactMarkdown>
      </Text>

      {record?.mainbanner?.media?.mediaone && (
        <div>
          <Title level={5}>Media One</Title>
          {record?.mainbanner?.media?.mediaone?.mime.split("/")[0] ===
          "image" ? (
            <Image
              src={cdnUrl + record?.mainbanner?.media?.mediaone?.url}
              width={100}
            />
          ) : (
            <video width="320" height="240" controls>
              <source
                src={cdnUrl + record?.mainbanner?.media?.mediaone?.url}
                // type="video/mp4"
              ></source>
            </video>
          )}
        </div>
      )}

      {record?.mainbanner?.media?.mediatwo && (
        <div>
          <Title level={5}>Media Two</Title>
          {record?.mainbanner?.media?.mediatwo?.mime.split("/")[0] ===
          "image" ? (
            <Image
              src={cdnUrl + record?.mainbanner?.media?.mediatwo?.url}
              width={100}
            />
          ) : (
            <video width="320" height="240" controls>
              <source
                src={cdnUrl + record?.mainbanner?.media?.mediatwo?.url}
                // type="video/mp4"
              ></source>
            </video>
          )}
        </div>
      )}

      {record?.mainbanner?.media?.mediathree && (
        <div>
          <Title level={5}>Media Three</Title>
          {record?.mainbanner?.media?.mediathree?.mime.split("/")[0] ===
          "image" ? (
            <Image
              src={cdnUrl + record?.mainbanner?.media?.mediathree?.url}
              width={100}
            />
          ) : (
            <video width="320" height="240" controls>
              <source
                src={cdnUrl + record?.mainbanner?.media?.mediathree?.url}
                // type="video/mp4"
              ></source>
            </video>
          )}
        </div>
      )}

      {record?.mainbanner?.media?.mediafour && (
        <div>
          <Title level={5}>Media Four</Title>
          {record?.mainbanner?.media?.mediafour?.mime.split("/")[0] ===
          "image" ? (
            <Image
              src={cdnUrl + record?.mainbanner?.media?.mediafour?.url}
              width={100}
            />
          ) : (
            <video width="320" height="240" controls>
              <source
                src={cdnUrl + record?.mainbanner?.media?.mediafour?.url}
                // type="video/mp4"
              ></source>
            </video>
          )}
        </div>
      )}

      {/* ------------------------------------------------ */}
      {/* ----------------- latest version features --------------- */}

      <h1
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          marginTop: "20px",
          textDecoration: "underline",
        }}
      >
        Latest features section
      </h1>

      <Title level={5}>Title</Title>
      <Text>{record?.latestFeatures?.title}</Text>

      <Title level={5}>Description</Title>
      <Text>
        {/* <div
          dangerouslySetInnerHTML={{
            __html: record?.latestFeatures?.description,
          }}
        /> */}
        <ReactMarkdown>{record?.latestFeatures?.description}</ReactMarkdown>
      </Text>

      {record?.latestFeatures?.image && (
        <div>
          <Title level={5}>Image</Title>
          <Image
            src={cdnUrl + record?.latestFeatures?.image?.url}
            width={100}
          />
        </div>
      )}

      {/* ------------------------------------------------ */}
      {/* ----------------- highlights --------------- */}

      <h1
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          marginTop: "20px",
          textDecoration: "underline",
        }}
      >
        Highlights section
      </h1>

      <Title level={5}>Title</Title>
      <Text>{record?.highlights?.title}</Text>

      <Title level={5}>Description</Title>
      <Text>
        <div
          dangerouslySetInnerHTML={{ __html: record?.highlights?.description }}
        />
      </Text>

      {/* ------------------------------------------------ */}
      {/* ----------------- features --------------- */}

      <h1
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          marginTop: "20px",
          textDecoration: "underline",
        }}
      >
        Features section
      </h1>

      <Title level={5}>Title</Title>
      <Text>{record?.features?.title}</Text>

      <Title level={5}>Description</Title>
      <Text>
        {/* <div
          dangerouslySetInnerHTML={{ __html: record?.features?.description }}
        /> */}
        <ReactMarkdown>{record?.features?.description}</ReactMarkdown>
      </Text>
    </Show>
  );
};
