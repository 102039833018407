import React, { useState } from "react";
import { IResourceComponentsProps, useApiUrl } from "@pankod/refine-core";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Create,
  Form,
  Input,
  Select,
  useForm,
  useSelect,
  Upload,
  Radio,
  Button,
  message,
} from "@pankod/refine-antd";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import {
  useStrapiUpload,
  mediaUploadMapper,
  getValueProps,
} from "@pankod/refine-strapi-v4";
// import { TOKEN_KEY } from "../../constants";
import { IPost } from "interfaces";

export const FilterCreate: React.FC<IResourceComponentsProps> = () => {
  const API_URL = useApiUrl();
  //   const [locale, setLocale] = useState("en");

  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const { formProps, saveButtonProps } = useForm<IPost>();

  //   const { selectProps } = useSelect({
  //     resource: "categories",
  //     // metaData: { locale },
  //   });

  //   const { ...uploadProps } = useStrapiUpload({
  //     maxCount: 1,
  //   });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinishFailed={() => {
          message.error("Submit failed!, Please check the input fields");
        }}
        onFinish={(values: any) => {
          var list: any = [];
          values?.list?.map((value: String) => {
            list.push({ value: value });
          });

          values["values"] = list;

          return (
            formProps.onFinish && formProps.onFinish(mediaUploadMapper(values))
          );
        }}
      >
        <Form.Item
          label="Label"
          name="label"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Field Name"
          name="fieldName"
          rules={[
            {
              required: true,
            },
            {
              required: true,
              pattern: new RegExp("^[A-Za-z][A-Za-z0-9]*$"),
              message: "Please remove white space and special characters",
            },
          ]}
        >
          <Input />
        </Form.Item>

        {/* ------------------------------- */}

        <Form.List
          name="list"
          //   rules={[
          //     {
          //       validator: async (_, names) => {
          //         if (!names || names.length < 2) {
          //           return Promise.reject(new Error("At least 2 passengers"));
          //         }
          //       },
          //     },
          //   ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  // {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                  label={index === 0 ? "Values" : ""}
                  required={false}
                  key={field.key}
                >
                  <Form.Item
                    {...field}
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please input value or delete this field.",
                      },
                    ]}
                    noStyle
                  >
                    <Input
                      placeholder="value"
                      style={{
                        width: "95%",
                      }}
                    />
                  </Form.Item>
                  {fields.length > 0 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                      style={{ marginLeft: "10px" }}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{
                    width: "100%",
                  }}
                  icon={<PlusOutlined />}
                >
                  Add value
                </Button>
                {/* <Button
                type="dashed"
                onClick={() => {
                  add('The head item', 0);
                }}
                style={{
                  width: '60%',
                  marginTop: '20px',
                }}
                icon={<PlusOutlined />}
              >
                Add field at head
              </Button> */}
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>

        {/* ------------------------------- */}
      </Form>
    </Create>
  );
};
