import { useShow } from "@pankod/refine-core";
import { Image, Show, Typography } from "@pankod/refine-antd";
import { cdnUrl } from "server";
import ReactMarkdown from "react-markdown";

const { Title, Text } = Typography;

export const ProductShow = () => {
  const { queryResult } = useShow({
    metaData: {
      populate: {
        image: {
          populate: "*",
        },
        component_type: {
          populate: "*",
        },
      },
    },
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Name</Title>
      <Text>{record?.name}</Text>

      <Title level={5}>Component Type</Title>
      <Text>{record?.component_type?.componentCategory}</Text>

      <Title level={5}>Description</Title>
      <Text>
        {/* <div dangerouslySetInnerHTML={{ __html: record?.description }} /> */}
        <ReactMarkdown>{record?.description}</ReactMarkdown>
      </Text>
      {record?.image && (
        <div>
          <Title level={5}>Image</Title>
          <Image src={cdnUrl + record?.image?.url} width={100} />
        </div>
      )}
      {console.log(
        "record?.specifications",
        record?.specifications,
        Array.isArray(record?.specifications)
      )}

      {record?.specifications && (
        <div>
          <Title level={5} style={{ marginTop: "20px" }}>
            Specifications
          </Title>

          {Array.isArray(record?.specifications)
            ? record?.specifications?.map((item: any, index: any) => {
                return (
                  <div
                    key={index}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <h1 style={{ textTransform: "capitalize" }}>
                      {item["key"]} :{"  "}
                    </h1>
                    <h4>
                      {"  "}
                      {item["value"]}
                    </h4>
                  </div>
                );
              })
            : Object?.keys(record?.specifications)?.map((key: any, i: any) => {
                return (
                  <div
                    key={i}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <h1 style={{ textTransform: "capitalize" }}>{key} : </h1>
                    <h4>{record?.specifications[key]}</h4>
                  </div>
                );
              })}
        </div>
      )}
    </Show>
  );
};
