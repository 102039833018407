import React, { useState } from "react";
import {
  IResourceComponentsProps,
  useApiUrl,
  useCustom,
  useMany,
  useOne,
} from "@pankod/refine-core";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Create,
  Form,
  Input,
  Select,
  useForm,
  useSelect,
  Upload,
  Radio,
  Button,
  Space,
  message,
} from "@pankod/refine-antd";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import {
  useStrapiUpload,
  mediaUploadMapper,
  getValueProps,
} from "@pankod/refine-strapi-v4";
// import { TOKEN_KEY } from "../../constants";
import { IPost } from "interfaces";
import { serverUrl } from "server";

export const ProductCreate: React.FC<IResourceComponentsProps> = () => {
  const [componentId, setComponentId] = useState(9);
  const API_URL = useApiUrl();
  const [image, setImage] = useState(null);
  //   const [locale, setLocale] = useState("en");

  const componentQueryResult = useOne({
    resource: "component-types",
    id: componentId,
    queryOptions: {
      enabled: true,
    },
    metaData: {
      populate: "deep",
    },
  });

  const { data, isLoading } = useCustom({
    url: `${serverUrl}/api/filters?populate=deep,6`,
    method: "get",
    metaData: {
      populate: "*",
    },
  });

  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const { formProps, saveButtonProps } = useForm<IPost>();

  const { selectProps } = useSelect({
    resource: "component-types",
    // metaData: { filters },
    optionLabel: "name",
    optionValue: "id",
    pagination: {
      pageSize: 20,
    },
  });

  //   const { options } = useSelect({
  //     resource: "filters",
  //     // optionLabel: "title",
  //     // optionValue: "id",
  //   });

  const { ...uploadProps } = useStrapiUpload({
    maxCount: 1,
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinishFailed={() => {
          message.error("Submit failed!, Please check the input fields");
        }}
        onFinish={(values: any) => {
          values["image"] = [image];
          //   for (key in values.specs){
          //     if (key in values.specifications){
          //         console.log(" Key exists")
          //     }else{
          //       values?.specs?.map((item: any) => {
          //         values.specifications[item?.key] = item?.value;
          //       });
          //     }
          // }
          values?.specs?.map((item: any) => {
            values.specifications[item?.key] = item?.value;
          });

          console.log("values", values);

          // let newValues = {
          //   component_type: values["component_type"],
          //   description: values["description"],
          //   image: values["image"],
          //   name: values["name"],
          //   specifications: values["specifications            "],
          // };
          // values?.specs?.map((item: any) => {
          //   newValues.specifications[item?.key] = item?.value;
          // });

          // console.log("newValues", newValues);

          return (
            formProps.onFinish && formProps.onFinish(mediaUploadMapper(values))
          );
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item>
        {/* <Form.Item
          label="Order"
          name="order"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item> */}

        <Form.Item
          label="Component Type"
          name="component_type"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            {...selectProps}
            onChange={(e: any) => {
              setComponentId(e);
            }}
          />
        </Form.Item>

        {componentId !== 0 && (
          <div>
            <h1 style={{ fontWeight: "600", color: "#626262" }}>
              Specifications
            </h1>
            {componentQueryResult?.data?.data?.filters.map(
              (item: any, index: any) => {
                return (
                  <Form.Item
                    key={index}
                    label={item?.label}
                    name={["specifications", item?.fieldName]}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    style={{ textTransform: "capitalize", fontWeight: "400" }}
                  >
                    {/* <Input /> */}
                    <Select>
                      {data?.data?.data
                        ?.find((f: any) => f?.id === item?.id)
                        .attributes?.values?.map((option: any) => (
                          <Select.Option value={option?.value}>
                            {option?.value}
                          </Select.Option>
                        ))}
                    </Select>

                    {/* <Select>{filterResult(item?.id)}</Select> */}
                  </Form.Item>
                );
              }
            )}

            <Form.List name="specs">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{
                        display: "flex",
                        marginBottom: 8,
                        width: "1000",
                      }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "key"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing key",
                          },
                          {
                            required: true,
                            pattern: new RegExp("^[A-Za-z][A-Za-z0-9]*$"),
                            message:
                              "Please remove white space and special characters",
                          },
                        ]}
                        style={{ width: "200px" }}
                      >
                        <Input
                          placeholder="Specification"
                          style={{ width: "200px" }}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "value"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing value",
                          },
                        ]}
                        style={{ width: "100%" }}
                      >
                        <Input placeholder="Value" style={{ width: "100%" }} />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add specification
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>
        )}

        <Form.Item label="Image">
          <Form.Item
            name="image"
            valuePropName="fileList"
            getValueProps={(data) => getValueProps(data, API_URL)}
            noStyle
          >
            <Upload.Dragger
              name="files"
              action={`${API_URL}/upload`}
              // headers={{
              //   Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
              // }}
              listType="picture"
              multiple
              showUploadList={{ showRemoveIcon: false }}
              {...uploadProps}
              onChange={(info: any) => {
                if (info.file.status === "done") {
                  setImage(info?.file?.response[0]?.id);
                }
              }}
            >
              <p className="ant-upload-text">
                Drag & drop an image in this area
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        {/* ------------------------------- */}
      </Form>
    </Create>
  );
};
