import React, { useState } from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  message,
  Select,
  useForm,
} from "@pankod/refine-antd";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
// import { TOKEN_KEY } from "../../constants";
import { IPost } from "interfaces";

export const CareerCreate: React.FC<IResourceComponentsProps> = () => {
  // const API_URL = useApiUrl();

  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const { formProps, saveButtonProps } = useForm<IPost>();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinishFailed={() => {
          message.error("Submit failed!, Please check the input fields");
        }}
        onFinish={(values: any) => {
          return (
            formProps.onFinish && formProps.onFinish(mediaUploadMapper(values))
          );
        }}
      >
        <Form.Item
          label="Designation"
          name="designation"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Job type"
          name="jobType"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            <option value="On-site">On-site</option>
            <option value="Remote">Remote</option>
            <option value="Hybrid">Hybrid</option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
