import { useState } from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";

import {
  List,
  Table,
  useTable,
  getDefaultSortOrder,
  FilterDropdown,
  Select,
  useSelect,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  ImageField,
  Form,
  Radio,
  Tag,
  ShowButton,
} from "@pankod/refine-antd";

import { IPost } from "interfaces";

// import { API_URL } from "../../constants";

export const SoftwareList: React.FC<IResourceComponentsProps> = () => {
  //   const [locale, setLocale] = useState("en");
  const [publicationState, setPublicationState] = useState("live");

  const { tableProps, sorter } = useTable<IPost>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    metaData: {
      populate: ["system", "mainbanner"],
      //   locale,
      publicationState,
    },
  });

  return (
    <List>
      <Form
        layout="inline"
        initialValues={{
          //   locale,
          publicationState,
        }}
      ></Form>
      <br />
      <Table
        {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
        }}
      >
        {/* <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter={{ multiple: 3 }}
        /> */}
        <Table.Column
          key="heading"
          title="Heading"
          dataIndex={["mainbanner", "heading"]}

          // render={(text, record) => {
          //   var data = "";
          //   text?.system?.map((value: any) => {
          //     if (value.__component === "sections.system-security-banner") {
          //       data = value.heading;
          //     }
          //   });

          //   return data;
          // }}
          //   defaultSortOrder={getDefaultSortOrder("systemCategory", sorter)}
          //   sorter={{ multiple: 2 }}
        />

        <Table.Column
          dataIndex="createdAt"
          title="Created At"
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter={{ multiple: 1 }}
        />

        <Table.Column<{ id: string }>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
              <ShowButton size="small" recordItemId={record.id} hideText />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
