import { useShow } from "@pankod/refine-core";
import { Image, Show, Typography } from "@pankod/refine-antd";
import { cdnUrl } from "server";
import ReactMarkdown from "react-markdown";

const { Title, Text } = Typography;

export const ComponentShow = () => {
  const { queryResult } = useShow({
    metaData: {
      populate: "deep",
      // {
      //   media: {
      //     populate: "*",
      //   },
      //   filters: {
      //     populate: "*",
      //   },
      // },
    },
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Page Title</Title>
      <Text>{record?.pageTitle}</Text>

      {record?.thumbnail && (
        <div>
          <Title level={5}>Thumbnail</Title>
          <Image src={cdnUrl + record?.thumbnail?.url} width={100} />
        </div>
      )}

      <Title level={5}>Name</Title>
      <Text>{record?.name}</Text>

      <Title level={5}>Component Category</Title>
      <Text>{record?.componentCategory}</Text>

      <Title level={5}>Description</Title>
      <Text>
        {/* <div dangerouslySetInnerHTML={{ __html: record?.description }} /> */}
        <ReactMarkdown>{record?.description}</ReactMarkdown>
      </Text>

      <Title level={5}>Filters</Title>
      <ul>
        {record?.filters?.map((value: any) => {
          return (
            <li>
              <Text>{value?.componentLabel}</Text>
            </li>
          );
        })}
      </ul>

      {record?.media?.mediaone && (
        <div>
          <Title level={5}>Media One</Title>
          {record?.media?.mediaone?.mime.split("/")[0] === "image" ? (
            <Image src={cdnUrl + record?.media?.mediaone?.url} width={100} />
          ) : (
            <video width="320" height="240" controls>
              <source
                src={cdnUrl + record?.media?.mediaone?.url}
                // type="video/mp4"
              ></source>
            </video>
          )}
          {/* <Image src={cdnUrl + record?.media?.mediaone?.url} width={100} /> */}
        </div>
      )}

      {record?.media?.mediatwo && (
        <div>
          <Title level={5}>Media Two</Title>
          {record?.media?.mediatwo?.mime.split("/")[0] === "image" ? (
            <Image src={cdnUrl + record?.media?.mediatwo?.url} width={100} />
          ) : (
            <video width="320" height="240" controls>
              <source
                src={cdnUrl + record?.media?.mediatwo?.url}
                // type="video/mp4"
              ></source>
            </video>
          )}
          {/* <Image src={cdnUrl + record?.media?.mediatwo?.url} width={100} /> */}
        </div>
      )}

      {record?.media?.mediathree && (
        <div>
          <Title level={5}>Media Three</Title>
          {record?.media?.mediathree?.mime.split("/")[0] === "image" ? (
            <Image src={cdnUrl + record?.media?.mediathree?.url} width={100} />
          ) : (
            <video width="320" height="240" controls>
              <source
                src={cdnUrl + record?.media?.mediathree?.url}
                // type="video/mp4"
              ></source>
            </video>
          )}
          {/* <Image src={cdnUrl + record?.media?.mediathree?.url} width={100} /> */}
        </div>
      )}

      {record?.media?.mediafour && (
        <div>
          <Title level={5}>Media Four</Title>
          {record?.media?.mediafour?.mime.split("/")[0] === "image" ? (
            <Image src={cdnUrl + record?.media?.mediafour?.url} width={100} />
          ) : (
            <video width="320" height="240" controls>
              <source
                src={cdnUrl + record?.media?.mediafour?.url}
                // type="video/mp4"
              ></source>
            </video>
          )}
          {/* <Image src={cdnUrl + record?.media?.mediafour?.url} width={100} /> */}
        </div>
      )}
    </Show>
  );
};
