import { useState } from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";

import {
  List,
  Table,
  useTable,
  getDefaultSortOrder,
  FilterDropdown,
  Select,
  useSelect,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  ImageField,
  Form,
  Radio,
  Tag,
  ShowButton,
} from "@pankod/refine-antd";

import { IPost } from "interfaces";
import { compareFilters } from "@pankod/refine-core/dist/definitions/table";

// import { API_URL } from "../../constants";

export const ProductList: React.FC<IResourceComponentsProps> = () => {
  //   const [locale, setLocale] = useState("en");
  const [publicationState, setPublicationState] = useState("live");

  const { tableProps, sorter } = useTable<IPost>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    metaData: {
      populate: ["component_type"],
      //   locale,
      publicationState,
    },
  });

  //   const { selectProps } = useSelect({
  //     resource: "categories",
  //     optionLabel: "title",
  //     optionValue: "id",
  //     metaData: { locale },
  //   });

  function strcmp(a: any, b: any) {
    if (a === b) {
      return 0;
    }

    if (a > b) {
      return 1;
    }

    return -1;
  }

  return (
    <List>
      <Form
        layout="inline"
        initialValues={{
          //   locale,
          publicationState,
        }}
      >
        {/* <Form.Item label="Locale" name="locale">
          <Radio.Group onChange={(e) => setLocale(e.target.value)}>
            <Radio.Button value="en">English</Radio.Button>
            <Radio.Button value="de">Deutsch</Radio.Button>
          </Radio.Group>
        </Form.Item> */}
        {/* <Form.Item label="Publication State" name="publicationState">
          <Radio.Group onChange={(e) => setPublicationState(e.target.value)}>
            <Radio.Button value="live">Published</Radio.Button>
            <Radio.Button value="preview">Draft and Published</Radio.Button>
          </Radio.Group>
        </Form.Item> */}
      </Form>
      <br />
      <Table
        {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
        }}
      >
        {/* <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter={{ multiple: 3 }}
        /> */}
        <Table.Column
          key="name"
          dataIndex="name"
          title="Name"
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter={{ multiple: 2 }}
        />
        <Table.Column
          dataIndex={["component_type", "name"]}
          // dataIndex="component_type"
          key="component_type"
          title="Component Type"
          // defaultSortOrder={getDefaultSortOrder("component_type", sorter)}

          sorter={{
            compare: (a: any, b: any) =>
              strcmp(a.component_type?.name, b.component_type?.name),
            multiple: 2,
          }}
          // sorter={{ multiple: 2 }}
        />
        {/* <Table.Column
          key="[category][id]"
          dataIndex={["category", "title"]}
          title="Category"
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder="Select Category"
                {...selectProps}
              />
            </FilterDropdown>
          )}
        /> */}
        <Table.Column
          dataIndex="createdAt"
          title="Created At"
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter={{ multiple: 1 }}
        />
        {/* <Table.Column
          dataIndex="publishedAt"
          title="Status"
          render={(value) => {
            return (
              <Tag color={value ? "green" : "blue"}>
                {value ? "Published" : "Draft"}
              </Tag>
            );
          }}
        /> */}
        {/* <Table.Column
          dataIndex={"cover"}
          align="center"
          title="Cover"
          render={(value) => {
            return value ? (
              <ImageField
                value={API_URL + value[0].url}
                alt={value[0]?.name}
                title={value[0]?.name}
                width={48}
                preview={{ mask: <></> }}
              />
            ) : (
              <span>---</span>
            );
          }}
        /> */}
        {/* <Table.Column
          dataIndex="publishedAt"
          title="Status"
          render={(value) => {
            return (
              <Tag color={value ? "green" : "blue"}>
                {value ? "Published" : "Draft"}
              </Tag>
            );
          }}
        /> */}
        <Table.Column<{ id: string }>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
              <ShowButton size="small" recordItemId={record.id} hideText />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
