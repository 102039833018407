import { useShow } from "@pankod/refine-core";
import { Show, Typography } from "@pankod/refine-antd";
import ReactMarkdown from "react-markdown";

const { Title, Text } = Typography;

export const CareerShow = () => {
  const { queryResult } = useShow({
    metaData: {
      //   populate: {
      //     values: {
      //       populate: "*",
      //     },
      //   },
    },
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Designantion</Title>
      <Text>{record?.designation}</Text>

      <Title level={5}>Job type</Title>
      <Text>{record?.jobType}</Text>

      <Title level={5}>Description</Title>
      {/* <Text>{record?.description}</Text> */}
      <ReactMarkdown>{record?.description}</ReactMarkdown>
    </Show>
  );
};
