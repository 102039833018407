import { useState } from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";

import {
  List,
  Table,
  useTable,
  getDefaultSortOrder,
  FilterDropdown,
  Select,
  useSelect,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  ImageField,
  Form,
  Radio,
  Tag,
  ShowButton,
} from "@pankod/refine-antd";

import { IPost } from "interfaces";

// import { API_URL } from "../../constants";

export const FilterList: React.FC<IResourceComponentsProps> = () => {
  //   const [locale, setLocale] = useState("en");
  const [publicationState, setPublicationState] = useState("live");

  const { tableProps, sorter } = useTable<IPost>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    metaData: {
      populate: ["values"],
      //   locale,
      publicationState,
    },
  });

  //   const { selectProps } = useSelect({
  //     resource: "categories",
  //     optionLabel: "title",
  //     optionValue: "id",
  //     metaData: { locale },
  //   });

  return (
    <List>
      <Form
        layout="inline"
        initialValues={{
          publicationState,
        }}
      ></Form>
      <br />
      <Table
        {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
        }}
      >
        {/* <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter={{ multiple: 3 }}
        /> */}
        <Table.Column
          key="label"
          dataIndex="label"
          title="Label"
          defaultSortOrder={getDefaultSortOrder("label", sorter)}
          sorter={{ multiple: 2 }}
        />
        <Table.Column
          key="fieldName"
          dataIndex="fieldName"
          title="Field Name"
          defaultSortOrder={getDefaultSortOrder("fieldName", sorter)}
          sorter={{ multiple: 2 }}
        />
        <Table.Column
          //   dataIndex={}
          key="value"
          title="Values"
          render={(text, record) => {
            return (
              <ul>
                {text?.values?.map((item: any) => {
                  return <li>{item?.value}</li>;
                })}
              </ul>
            );
          }}
          //   defaultSortOrder={getDefaultSortOrder("systemCategory", sorter)}
          //   sorter={{ multiple: 2 }}
        />
        {/* <Table.Column
          key="[category][id]"
          dataIndex={["category", "title"]}
          title="Category"
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder="Select Category"
                {...selectProps}
              />
            </FilterDropdown>
          )}
        /> */}
        <Table.Column
          dataIndex="createdAt"
          title="Created At"
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter={{ multiple: 1 }}
        />
        {/* <Table.Column
          dataIndex="publishedAt"
          title="Status"
          render={(value) => {
            return (
              <Tag color={value ? "green" : "blue"}>
                {value ? "Published" : "Draft"}
              </Tag>
            );
          }}
        /> */}
        {/* <Table.Column
          dataIndex={"cover"}
          align="center"
          title="Cover"
          render={(value) => {
            return value ? (
              <ImageField
                value={API_URL + value[0].url}
                alt={value[0]?.name}
                title={value[0]?.name}
                width={48}
                preview={{ mask: <></> }}
              />
            ) : (
              <span>---</span>
            );
          }}
        /> */}

        {/* <Table.Column
          dataIndex="publishedAt"
          title="Status"
          render={(value) => {
            return (
              <Tag color={value ? "green" : "blue"}>
                {value ? "Published" : "Draft"}
              </Tag>
            );
          }}
        /> */}

        <Table.Column<{ id: string }>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
              <ShowButton size="small" recordItemId={record.id} hideText />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
