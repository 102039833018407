import React, { useEffect, useState } from "react";
import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Edit,
  Form,
  Input,
  Select,
  useForm,
  useSelect,
  Upload,
  Radio,
  Button,
  PageHeader,
  message,
} from "@pankod/refine-antd";
import {
  useStrapiUpload,
  getValueProps,
  mediaUploadMapper,
} from "@pankod/refine-strapi-v4";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";

// import { TOKEN_KEY, API_URL } from "../../constants";
import { ICategory, IPost } from "interfaces";

export const FilterEdit: React.FC<IResourceComponentsProps> = () => {
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const [form] = Form.useForm();
  const { formProps, saveButtonProps } = useForm<IPost>({
    metaData: { populate: ["values"] },
  });

  const { queryResult } = useShow({
    metaData: {
      populate: {
        values: {
          populate: "*",
        },
      },
    },
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  useEffect(() => {
    if (record) {
      form.resetFields();
    }
  }, [record]);

  const routes = [
    {
      path: "",
      breadcrumbName: "Filters",
    },
    {
      path: "",
      breadcrumbName: "Edit",
    },
  ];

  return (
    // <Edit saveButtonProps={saveButtonProps} isLoading={isLoading}>
    <div
      style={{
        // border: "1px solid red",
        backgroundColor: "white",
        borderRadius: "6px",
      }}
    >
      <PageHeader
        className="site-page-header"
        title="Edit filters"
        onBack={() => window.history.back()}
        breadcrumb={{
          routes,
        }}
      >
        <Form
          {...formProps}
          form={form}
          layout="vertical"
          // eslint-disable-next-line
          initialValues={{
            label: record?.label,
            fieldName: record?.fieldName,
            list: record?.values.map((value: any) => value?.value),
          }}
          onFinishFailed={() => {
            message.error("Submit failed!, Please check the input fields");
          }}
          onFinish={(values: any) => {
            var list: any = [];
            values?.list?.map((value: String) => {
              list.push({ value: value });
            });

            values["values"] = list;
            return formProps.onFinish?.(mediaUploadMapper(values));
          }}
        >
          <Form.Item
            label="Label"
            name="label"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Field Name"
            name="fieldName"
            rules={[
              {
                required: true,
              },
              {
                required: true,
                pattern: new RegExp("^[A-Za-z][A-Za-z0-9]*$"),
                message: "Please remove white space and special characters",
              },
            ]}
          >
            <Input />
          </Form.Item>

          {/* ------------------------------- */}

          <Form.List
            name="list"
            //   rules={[
            //     {
            //       validator: async (_, names) => {
            //         if (!names || names.length < 2) {
            //           return Promise.reject(new Error("At least 2 passengers"));
            //         }
            //       },
            //     },
            //   ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    // {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    label={index === 0 ? "Values" : ""}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please input value or delete this field.",
                        },
                      ]}
                      noStyle
                    >
                      <Input
                        placeholder="value"
                        style={{
                          width: "95%",
                        }}
                      />
                    </Form.Item>
                    {fields.length > 0 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                        style={{ marginLeft: "10px" }}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{
                      width: "100%",
                    }}
                    icon={<PlusOutlined />}
                  >
                    Add value
                  </Button>

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button type="primary" onClick={() => form.submit()}>
              Save
            </Button>
          </div>
        </Form>
      </PageHeader>
    </div>
    // </Edit>
  );
};
