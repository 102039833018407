import { useState } from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";

import {
  List,
  Table,
  useTable,
  getDefaultSortOrder,
  FilterDropdown,
  Select,
  useSelect,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  ImageField,
  Form,
  Radio,
  Tag,
  ShowButton,
} from "@pankod/refine-antd";

import { IPost } from "interfaces";

// import { API_URL } from "../../constants";

export const SystemList: React.FC<IResourceComponentsProps> = () => {
  //   const [locale, setLocale] = useState("en");
  const [publicationState, setPublicationState] = useState("live");

  const { tableProps, sorter } = useTable<IPost>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    metaData: {
      populate: ["system", "mainbanner"],
      //   locale,
      publicationState,
    },
  });

  //   const { selectProps } = useSelect({
  //     resource: "categories",
  //     optionLabel: "title",
  //     optionValue: "id",
  //     metaData: { locale },
  //   });

  return (
    <List>
      <Form
        layout="inline"
        initialValues={{
          //   locale,
          publicationState,
        }}
      >
        {/* <Form.Item label="Locale" name="locale">
          <Radio.Group onChange={(e) => setLocale(e.target.value)}>
            <Radio.Button value="en">English</Radio.Button>
            <Radio.Button value="de">Deutsch</Radio.Button>
          </Radio.Group>
        </Form.Item> */}

        {/* <Form.Item label="Publication State" name="publicationState">
          <Radio.Group onChange={(e) => setPublicationState(e.target.value)}>
            <Radio.Button value="live">Published</Radio.Button>
            <Radio.Button value="preview">Draft and Published</Radio.Button>
          </Radio.Group>
        </Form.Item> */}
      </Form>
      <br />
      <Table
        {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
        }}
      >
        {/* <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter={{ multiple: 3 }}
        /> */}
        <Table.Column
          key="systemCategory"
          title="Heading"
          dataIndex={["mainbanner", "heading"]}

          // render={(text, record) => {
          //   var data = "";
          //   text?.system?.map((value: any) => {
          //     if (value.__component === "sections.system-security-banner") {
          //       data = value.heading;
          //     }
          //   });

          //   return data;
          // }}
          //   defaultSortOrder={getDefaultSortOrder("systemCategory", sorter)}
          //   sorter={{ multiple: 2 }}
        />
        <Table.Column
          dataIndex="systemCategory"
          key="systemCategory"
          title="Category"
          defaultSortOrder={getDefaultSortOrder("systemCategory", sorter)}
          sorter={{ multiple: 2 }}
        />
        {/* <Table.Column
          key="[category][id]"
          dataIndex={["category", "title"]}
          title="Category"
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder="Select Category"
                {...selectProps}
              />
            </FilterDropdown>
          )}
        /> */}
        <Table.Column
          dataIndex="createdAt"
          title="Created At"
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter={{ multiple: 1 }}
        />

        {/* <Table.Column
          dataIndex="publishedAt"
          title="Status"
          render={(value) => {
            return (
              <Tag color={value ? "green" : "blue"}>
                {value ? "Published" : "Draft"}
              </Tag>
            );
          }}
        /> */}
        <Table.Column<{ id: string }>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
              <ShowButton size="small" recordItemId={record.id} hideText />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
