import React, { useEffect, useState } from "react";
import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
// import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  // Edit,
  Form,
  Input,
  Select,
  useForm,
  // useSelect,
  // Upload,
  // Radio,
  Button,
  PageHeader,
  message,
} from "@pankod/refine-antd";
import {
  // useStrapiUpload,
  // getValueProps,
  mediaUploadMapper,
} from "@pankod/refine-strapi-v4";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";

// import { TOKEN_KEY, API_URL } from "../../constants";
import { IPost } from "interfaces";

export const CareerEdit: React.FC<IResourceComponentsProps> = () => {
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const [form] = Form.useForm();

  const { formProps } = useForm<IPost>({
    metaData: { populate: ["values"] },
  });

  const { queryResult } = useShow({
    metaData: {
      populate: {
        media: {
          populate: "*",
        },
        filters: {
          populate: "*",
        },
      },
    },
  });
  const { data } = queryResult;
  const record = data?.data;

  // const data =

  // const { selectProps } = useSelect({
  //   resource: "filters",
  //   // metaData: { filters },
  //   optionLabel: "name",
  //   optionValue: "id",
  // });

  // const { ...uploadProps } = useStrapiUpload({
  //   maxCount: 1,
  // });

  const routes = [
    {
      path: "",
      breadcrumbName: "Careers",
    },
    {
      path: "",
      breadcrumbName: "Edit",
    },
  ];

  useEffect(() => {
    if (record) {
      form.resetFields();
    }
  }, [record]);

  return (
    // <Edit saveButtonProps={saveButtonProps} isLoading={isLoading}>
    <div
      style={{
        // border: "1px solid red",
        backgroundColor: "white",
        borderRadius: "6px",
      }}
    >
      <PageHeader
        className="site-page-header"
        title="Edit careers"
        onBack={() => window.history.back()}
        breadcrumb={{
          routes,
        }}
      >
        <Form
          {...formProps}
          layout="vertical"
          form={form}
          // eslint-disable-next-line
          initialValues={{
            ...record,
          }}
          onFinishFailed={() => {
            message.error("Submit failed!, Please check the input fields");
          }}
          onFinish={(values: any) => {
            // const media = {
            //   mediaone: mediaOne,
            //   mediatwo: mediaTwo,
            //   mediathree: mediaThree,
            //   mediafour: mediaFour,
            // };
            // values["media"] = media;
            // console.log("values");
            // console.log(values);

            return formProps.onFinish?.(mediaUploadMapper(values));
          }}
        >
          <Form.Item
            label="Designation"
            name="designation"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Job type"
            name="jobType"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select>
              <option value="On-site">On-site</option>
              <option value="Remote">Remote</option>
              <option value="Hybrid">Hybrid</option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <ReactMde
              selectedTab={selectedTab}
              onTabChange={setSelectedTab}
              generateMarkdownPreview={(markdown) =>
                Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
              }
            />
          </Form.Item>

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button type="primary" onClick={() => form.submit()}>
              Save
            </Button>
          </div>
        </Form>
      </PageHeader>
    </div>
    // </Edit>
  );
};
