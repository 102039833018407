import React, { useEffect, useMemo, useState } from "react";
import {
  IResourceComponentsProps,
  useCustom,
  useOne,
  useShow,
} from "@pankod/refine-core";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Edit,
  Form,
  Input,
  Select,
  useForm,
  useSelect,
  Upload,
  Radio,
  Button,
  UploadFile,
  PageHeader,
  Space,
  message,
} from "@pankod/refine-antd";
import {
  useStrapiUpload,
  getValueProps,
  mediaUploadMapper,
} from "@pankod/refine-strapi-v4";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";

// import { TOKEN_KEY, API_URL } from "../../constants";
import { ICategory, IPost } from "interfaces";
import { cdnUrl, serverUrl } from "server";

export const ProductEdit: React.FC<IResourceComponentsProps> = () => {
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const [image, setImage] = useState(null);
  const [componentId, setComponentId] = useState(0);
  // const [extraSpec, setExtraSpec] = useState<any>(null);
  const [filelist, setFilelist] = useState(null);
  const [form] = Form.useForm();

  const { formProps, saveButtonProps } = useForm<IPost>({
    metaData: { populate: ["values"] },
  });

  interface fileList {
    uid?: Number;
    name?: String;
    status?: String;
    url?: URL;
  }

  const componentQueryResult = useOne({
    resource: "component-types",
    id: componentId,
    queryOptions: {
      enabled: true,
    },
    metaData: {
      populate: "*",
    },
  });

  const { queryResult } = useShow({
    metaData: {
      populate: {
        image: {
          populate: "*",
        },
        component_type: {
          populate: "*",
        },
      },
    },
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  // console.log("record", record);

  const { selectProps } = useSelect({
    resource: "component-types",
    defaultValue: record?.component_type?.id,
    // metaData: { locale: queryResult?.data?.data.locale },
    optionLabel: "name",
    optionValue: "id",
    pagination: {
      pageSize: 20,
    },
  });

  const { ...uploadProps } = useStrapiUpload({
    maxCount: 1,
  });

  const routes = [
    {
      path: "",
      breadcrumbName: "Products",
    },
    {
      path: "",
      breadcrumbName: "Edit",
    },
  ];

  const { data: filters, isLoading: filterLoading } = useCustom({
    url: `${serverUrl}/api/filters?populate=deep,6`,
    method: "get",
    metaData: {
      populate: "*",
    },
  });

  let filterss: any[] = [];

  componentQueryResult?.data?.data?.filters?.map((item: any) => {
    filterss.push(item?.fieldName);
  });

  const extraSpecs = (filterss: any) => {
    // console.log("filterss---", filterss);
    // form.resetFields();
    if (filterss.length !== 0) {
      let newSpec = { ...record?.specifications };

      filterss.map((item: any) => {
        delete newSpec[item];
      });

      var extraSpec = Object.entries(newSpec).map(([key, value]) => ({
        key,
        value,
      }));

      console.log("extra spec", extraSpec);

      return extraSpec;
    } else {
      return [];
    }
  };

  const specs = useMemo(() => extraSpecs(filterss), [filterss]);

  console.log("specs==============", specs);

  // const specs = extraSpecs(filterss);
  // form.resetFields();

  useEffect(() => {
    if (specs.length !== 0) {
      form.setFieldsValue({
        specs: specs,
      });
    }
  }, [specs]);

  useEffect(() => {
    if (record) {
      form.resetFields();
      if (record?.image) {
        form.setFieldsValue({
          image: [record?.image],
        });
        setImage(record?.image?.id);
      }
      // form.setFieldsValue({
      //   component_type: record?.component_type?.id,
      // });
      setComponentId(record?.component_type?.id);
    }
  }, [record]);

  // useEffect(() => {
  //   if (record) {
  //     // form.resetFields();
  //     setComponentId(record?.component_type?.id);
  //   }
  // }, [record]);

  return (
    // <Edit saveButtonProps={saveButtonProps}>
    <div
      style={{
        // border: "1px solid red",
        backgroundColor: "white",
        borderRadius: "6px",
      }}
    >
      <PageHeader
        className="site-page-header"
        title="Edit products"
        onBack={() => window.history.back()}
        breadcrumb={{
          routes,
        }}
      >
        {specs && (
          <Form
            {...formProps}
            layout="vertical"
            // eslint-disable-next-line
            form={form}
            onFinishFailed={() => {
              message.error("Submit failed!, Please check the input fields");
            }}
            initialValues={{
              // ...record,
              name: record?.name,
              // order: record?.order,
              description: record?.description,
              component_type: record?.component_type?.id,
              specifications: record?.specifications
                ? record?.specifications !== null
                  ? record?.specifications
                  : {}
                : {},
              specs: specs,
              // image: [record?.image],
              // image: [image],
            }}
            onFinish={async (values: any) => {
              values["image"] = image;
              console.log("values", values);

              if (values.specifications === undefined) {
                values["specifications"] = {};
                await values?.specs?.map((item: any) => {
                  values.specifications[item?.key] = item?.value;
                });
                console.log("values1", values);

                return formProps.onFinish?.(mediaUploadMapper(values));
              } else {
                await values?.specs?.map((item: any) => {
                  values.specifications[item?.key] = item?.value;
                });
                console.log("values2", values);

                return formProps.onFinish?.(mediaUploadMapper(values));
              }
            }}
          >
            {/* {console.log("extraaaa", extraSpec)} */}

            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <ReactMde
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={(markdown) =>
                  Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                }
              />
            </Form.Item>
            {/* <Form.Item
              label="Order"
              name="order"
              rules={[
                {
                  // required: true,
                },
              ]}
            >
              <Input type="number" />
            </Form.Item> */}

            <Form.Item
              label="Component Type"
              name="component_type"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                {...selectProps}
                onChange={(e: any) => {
                  setComponentId(e);
                  // form.setFieldsValue({
                  //   component_type: record?.component_type?.id,
                  // });
                }}
              />
            </Form.Item>

            {componentId !== 0 && (
              <div>
                <h1 style={{ fontWeight: "600", color: "#626262" }}>
                  Specifications
                </h1>
                {componentQueryResult?.data?.data?.filters.map((item: any) => {
                  return (
                    <Form.Item
                      key={item?.id}
                      label={item?.label}
                      name={["specifications", item?.fieldName]}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      style={{
                        textTransform: "capitalize",
                        fontWeight: "400",
                      }}
                    >
                      <Select>
                        {filters?.data?.data
                          ?.find((f: any) => f?.id === item?.id)
                          .attributes?.values?.map((option: any) => (
                            <Select.Option
                              key={option?.value}
                              value={option?.value}
                            >
                              {option?.value}
                            </Select.Option>
                          ))}
                      </Select>
                      {/* <Input /> */}
                    </Form.Item>
                  );
                })}

                <Form.List name="specs">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: "flex",
                            marginBottom: 8,
                            width: "1000",
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "key"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing key",
                              },
                              {
                                required: true,
                                pattern: new RegExp("^[A-Za-z][A-Za-z0-9]*$"),
                                message:
                                  "Please remove white space and special characters",
                              },
                            ]}
                            style={{ width: "200px" }}
                          >
                            <Input
                              placeholder="Specification"
                              style={{ width: "200px" }}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "value"]}
                            rules={[
                              {
                                required: true,
                                message: "Value is required",
                              },
                            ]}
                            style={{ width: "100%" }}
                          >
                            <Input
                              placeholder="Value"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add specification
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </div>
            )}
            {record && (
              <Form.Item label="Image">
                <Form.Item
                  name="image"
                  valuePropName="fileList"
                  getValueProps={(data) => getValueProps(data, serverUrl)}
                  noStyle
                >
                  <Upload.Dragger
                    name="files"
                    action={`${serverUrl}/api/upload`}
                    // headers={{
                    //   Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
                    // }}
                    listType="picture"
                    multiple
                    showUploadList={{ showRemoveIcon: false }}
                    {...uploadProps}
                    onChange={(info: any) => {
                      if (info.file.status === "done") {
                        setImage(info?.file?.response[0]?.id);
                      }
                    }}
                  >
                    <p className="ant-upload-text">
                      Drag & drop an image in this area
                    </p>
                  </Upload.Dragger>
                </Form.Item>
              </Form.Item>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Button type="primary" onClick={() => form.submit()}>
                Save
              </Button>
            </div>
          </Form>
        )}
      </PageHeader>
    </div>
    // </Edit>
  );
};
