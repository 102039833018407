import React, { useEffect, useState } from "react";
import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
// import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  // Edit,
  Form,
  Input,
  Select,
  useForm,
  useSelect,
  Upload,
  // Radio,
  Button,
  PageHeader,
  Switch,
  message,
} from "@pankod/refine-antd";
import {
  useStrapiUpload,
  getValueProps,
  mediaUploadMapper,
} from "@pankod/refine-strapi-v4";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";

// import { TOKEN_KEY, API_URL } from "../../constants";
import { IPost } from "interfaces";
import { serverUrl } from "server";

export const ComponentEdit: React.FC<IResourceComponentsProps> = () => {
  const [mediaOne, setMediaOne] = useState(null);
  const [mediaTwo, setMediaTwo] = useState(null);
  const [mediaThree, setMediaThree] = useState(null);
  const [mediaFour, setMediaFour] = useState(null);
  const [internalPage, setInternalPage] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);

  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const [form] = Form.useForm();

  const { formProps, saveButtonProps } = useForm<IPost>({
    metaData: { populate: "thumbnail" },
  });

  const { queryResult } = useShow({
    metaData: {
      populate: "deep",
      // {
      //   media: {
      //     populate: "*",
      //   },
      //   filters: {
      //     populate: "*",
      //   },
      //   thumnail: {
      //     populate: "*",
      //   },
      // },
    },
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  // const data =

  const { selectProps } = useSelect({
    resource: "filters",
    // metaData: { filters },
    optionLabel: "componentLabel",
    optionValue: "id",
  });

  const { ...uploadProps } = useStrapiUpload({
    maxCount: 1,
  });

  const routes = [
    {
      path: "",
      breadcrumbName: "Component types",
    },
    {
      path: "",
      breadcrumbName: "Edit",
    },
  ];

  useEffect(() => {
    if (record) {
      form.resetFields();
      if (record?.media?.mediaone) {
        form.setFieldsValue({
          media: { mediaone: [record?.media?.mediaone] },
        });
        setMediaOne(record?.media?.mediaone?.id);
      }
      if (record?.media?.mediatwo) {
        form.setFieldsValue({
          media: { mediatwo: [record?.media?.mediatwo] },
        });
        setMediaTwo(record?.media?.mediatwo?.id);
      }
      if (record?.media?.mediathree) {
        form.setFieldsValue({
          media: { mediathree: [record?.media?.mediathree] },
        });
        setMediaThree(record?.media?.mediathree?.id);
      }
      if (record?.media?.mediafour) {
        form.setFieldsValue({
          media: { mediafour: [record?.media?.mediafour] },
        });
        setMediaFour(record?.media?.mediafour?.id);
      }
      if (record?.haveInternalPage) {
        form.setFieldsValue({
          haveInternalPage: record?.haveInternalPage,
        });
        setInternalPage(record?.haveInternalPage);
      }
      console.log("RecorD", record);

      if (record?.thumbnail) {
        form.setFieldsValue({
          thumbnail: [record?.thumbnail],
        });
        setThumbnail(record?.thumbnail?.id);
      }
    }
  }, [record]);

  console.log("internal", internalPage);

  return (
    // <Edit saveButtonProps={saveButtonProps} isLoading={isLoading}>
    <div
      style={{
        // border: "1px solid red",
        backgroundColor: "white",
        borderRadius: "6px",
      }}
    >
      <PageHeader
        className="site-page-header"
        title="Edit component types"
        onBack={() => window.history.back()}
        breadcrumb={{
          routes,
        }}
      >
        <Form
          {...formProps}
          layout="vertical"
          form={form}
          scrollToFirstError={true}
          // eslint-disable-next-line
          initialValues={{
            pageTitle: record?.pageTitle,
            order: record?.order,
            name: record?.name,
            description: record?.description,
            filters: record?.filters.map((value: any) => value.id),
            componentCategory: record?.componentCategory,
            // haveInternalPage: internalPage,
          }}
          onFinishFailed={() => {
            message.error("Submit failed!, Please check the input fields");
          }}
          onFinish={(values: any) => {
            const media = {
              mediaone: mediaOne,
              mediatwo: mediaTwo,
              mediathree: mediaThree,
              mediafour: mediaFour,
            };
            values["media"] = media;
            values["slug"] = values.pageTitle
              .replace(/[^a-zA-Z0-9 ]/g, "")
              .toLowerCase()
              .split(" ")
              .join("-");
            values["thumbnail"] = thumbnail;
            values["haveInternalPage"] = internalPage;

            return formProps.onFinish?.(mediaUploadMapper(values));
          }}
        >
          <Form.Item
            label="Page title"
            name="pageTitle"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Order"
            name="order"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item label="Thumbnail">
            <Form.Item
              name="thumbnail"
              valuePropName="fileList"
              getValueProps={(data) => getValueProps(data, serverUrl)}
              noStyle
            >
              <Upload.Dragger
                name="files"
                action={`${serverUrl}/api/upload`}
                listType="picture"
                multiple
                {...uploadProps}
                onChange={(info: any) => {
                  if (info.file.status === "done") {
                    setThumbnail(info?.file?.response[0]?.id);
                  }
                }}
              >
                <p className="ant-upload-text">
                  Drag & drop a file in this area
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>

          <Form.Item
            label="Have internal page?"
            name="haveInternalPage"
            valuePropName="checked"
          >
            <Switch
              checked={internalPage}
              onChange={(check) => {
                setInternalPage(check);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <ReactMde
              selectedTab={selectedTab}
              onTabChange={setSelectedTab}
              generateMarkdownPreview={(markdown) =>
                Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
              }
            />
          </Form.Item>

          <Form.Item
            label="Filters"
            name="filters"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select {...selectProps} mode="multiple" allowClear />
          </Form.Item>

          <Form.Item
            label="Component Category"
            name="componentCategory"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select>
              <option value="Optics">Optics</option>
              <option value="Camera">Camera</option>
              <option value="Software">Software</option>
              <option value="Accessories">Accessories</option>
              <option value="FrameGrabber">Frame Grabber</option>
            </Select>
          </Form.Item>

          <Form.Item label="Media One">
            <Form.Item
              name={["media", "mediaone"]}
              valuePropName="fileList"
              getValueProps={(data) => getValueProps(data, serverUrl)}
              noStyle
            >
              <Upload.Dragger
                name="files"
                action={`${serverUrl}/api/upload`}
                // headers={{
                //   Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
                // }}
                listType="picture"
                multiple
                {...uploadProps}
                onChange={(info: any) => {
                  if (info.file.status === "done") {
                    setMediaOne(info?.file?.response[0]?.id);
                  }
                }}
              >
                <p className="ant-upload-text">
                  Drag & drop a file in this area
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>

          <Form.Item label="Media Two">
            <Form.Item
              name={["media", "mediatwo"]}
              valuePropName="fileList"
              getValueProps={(data) => getValueProps(data, serverUrl)}
              noStyle
            >
              <Upload.Dragger
                name="files"
                action={`${serverUrl}/api/upload`}
                // headers={{
                //   Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
                // }}
                listType="picture"
                multiple
                {...uploadProps}
                onChange={(info: any) => {
                  if (info.file.status === "done") {
                    setMediaTwo(info?.file?.response[0]?.id);
                  }
                }}
              >
                <p className="ant-upload-text">
                  Drag & drop a file in this area
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>

          <Form.Item label="Media Three">
            <Form.Item
              name={["media", "mediathree"]}
              valuePropName="fileList"
              getValueProps={(data) => getValueProps(data, serverUrl)}
              noStyle
            >
              <Upload.Dragger
                name="files"
                action={`${serverUrl}/api/upload`}
                // headers={{
                //   Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
                // }}
                listType="picture"
                multiple
                {...uploadProps}
                onChange={(info: any) => {
                  if (info.file.status === "done") {
                    setMediaThree(info?.file?.response[0]?.id);
                  }
                }}
              >
                <p className="ant-upload-text">
                  Drag & drop a file in this area
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>

          <Form.Item label="Media Four">
            <Form.Item
              name={["media", "mediafour"]}
              valuePropName="fileList"
              getValueProps={(data) => getValueProps(data, serverUrl)}
              noStyle
            >
              <Upload.Dragger
                name="files"
                action={`${serverUrl}/api/upload`}
                // headers={{
                //   Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
                // }}
                listType="picture"
                multiple
                {...uploadProps}
                onChange={(info: any) => {
                  if (info.file.status === "done") {
                    setMediaFour(info?.file?.response[0]?.id);
                  }
                }}
              >
                <p className="ant-upload-text">
                  Drag & drop a file in this area
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button type="primary" onClick={() => form.submit()}>
              Save
            </Button>
          </div>
        </Form>
      </PageHeader>
    </div>
    // </Edit>
  );
};
