import { useShow, useOne, useApiUrl } from "@pankod/refine-core";
import { Show, Typography, Tag, Image, Row, Col } from "@pankod/refine-antd";
import ReactMarkdown from "react-markdown";

import { IPost, ICategory } from "interfaces";
import { cdnUrl } from "server";
// import { API_URL } from "constants";

const { Title, Text } = Typography;

export const SystemShow = () => {
  const API_URL = useApiUrl();

  const { queryResult } = useShow({
    metaData: {
      populate: "deep",
    },
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { data: categoryData } = useOne({
    resource: "categories",
    id: record?.category?.id || "",
    queryOptions: {
      enabled: !!record?.category?.id,
    },
  });

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Page Title</Title>
      <Text>{record?.pageTitle}</Text>

      {record?.thumbnail && (
        <div>
          <Title level={5}>Thumbnail</Title>
          <Image src={cdnUrl + record?.thumbnail?.url} width={100} />
        </div>
      )}

      <Title level={5}>System Category</Title>
      <Text>{record?.systemCategory}</Text>

      <Title level={5}>Card Description</Title>
      {/* <Text>{record?.description}</Text> */}
      <ReactMarkdown>{record?.description}</ReactMarkdown>

      {/* ----------------- main banner --------------------- */}

      <h1
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          marginTop: "20px",
          textDecoration: "underline",
        }}
      >
        Main Banner
      </h1>

      <Title level={5}>Heading</Title>
      <Text>{record?.mainbanner?.heading}</Text>

      <Title level={5}>Description</Title>
      <Text>
        {/* <div
          dangerouslySetInnerHTML={{ __html: record?.mainbanner?.description }}
        /> */}
        <ReactMarkdown>{record?.mainbanner?.description}</ReactMarkdown>
      </Text>

      {record?.mainbanner?.media?.mediaone && (
        <div>
          <Title level={5}>Media One</Title>
          <Image
            src={cdnUrl + record?.mainbanner?.media?.mediaone?.url}
            width={100}
          />
        </div>
      )}

      {record?.mainbanner?.media?.mediatwo && (
        <div>
          <Title level={5}>Media Two</Title>
          <Image
            src={cdnUrl + record?.mainbanner?.media?.mediatwo?.url}
            width={100}
          />
        </div>
      )}

      {record?.mainbanner?.media?.mediathree && (
        <div>
          <Title level={5}>Media Three</Title>
          <Image
            src={cdnUrl + record?.mainbanner?.media?.mediathree?.url}
            width={100}
          />
        </div>
      )}

      {record?.mainbanner?.media?.mediafour && (
        <div>
          <Title level={5}>Media Four</Title>
          <Image
            src={cdnUrl + record?.mainbanner?.media?.mediafour?.url}
            width={100}
          />
        </div>
      )}

      {/* ------------------------------------------------ */}
      {/* ----------------- specifications --------------- */}

      <h1
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          marginTop: "20px",
          textDecoration: "underline",
        }}
      >
        Specification section
      </h1>

      <Title level={5}>Heading</Title>
      <Text>{record?.specifications?.heading}</Text>

      <Title level={5}>Specifications</Title>
      {record?.specifications?.specification?.map((spec: any) => {
        return (
          <Row gutter={4}>
            <Col span={4}>
              <Text style={{ fontWeight: "600" }}>{spec?.title}</Text>
            </Col>
            <Col span={20}>
              {/* <Text>: {spec?.description}</Text> */}
              <div>
                {/* <div dangerouslySetInnerHTML={{ __html: spec?.description }} /> */}
                <ReactMarkdown>{record?.mainbanner?.description}</ReactMarkdown>
              </div>
            </Col>
          </Row>
        );
      })}

      {/* ------------------------------------------------ */}
      {/* ----------------- highlights --------------- */}

      <h1
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          marginTop: "20px",
          textDecoration: "underline",
        }}
      >
        Highlights section
      </h1>

      <Title level={5}>Title</Title>
      <Text>{record?.highlights?.title}</Text>

      <Title level={5}>Description</Title>
      <Text>
        <div
          dangerouslySetInnerHTML={{ __html: record?.highlights?.description }}
        />
      </Text>

      {record?.highlights?.image && (
        <div>
          <Title level={5}>Image</Title>
          <Image src={cdnUrl + record?.highlights?.image?.url} width={100} />
        </div>
      )}

      {/* ------------------------------------------------ */}
      {/* ----------------- scope --------------- */}

      <h1
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          marginTop: "20px",
          textDecoration: "underline",
        }}
      >
        Scope section
      </h1>

      <Title level={5}>Heading</Title>
      <Text>{record?.scope?.heading}</Text>

      <Title level={5}>Scopes</Title>
      {record?.scope?.scope?.map((item: any) => {
        return (
          <div>
            <Row gutter={4} align="middle">
              <Col span={8}>
                <Text>{item?.title}</Text>
              </Col>
              <Col>
                :<Image src={cdnUrl + item?.image?.url} width={100} />
              </Col>
            </Row>
          </div>
        );
      })}
    </Show>
  );
};
