import React, { useState } from "react";
import { IResourceComponentsProps, useApiUrl } from "@pankod/refine-core";
// import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Create,
  Form,
  Input,
  Select,
  useForm,
  useSelect,
  Upload,
  // Radio,
  // Button,
  Switch,
  message,
} from "@pankod/refine-antd";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import {
  useStrapiUpload,
  mediaUploadMapper,
  getValueProps,
} from "@pankod/refine-strapi-v4";
// import { TOKEN_KEY } from "../../constants";
import { IPost } from "interfaces";

export const ComponentCreate: React.FC<IResourceComponentsProps> = () => {
  const API_URL = useApiUrl();
  //   const [locale, setLocale] = useState("en");
  const [mediaOne, setMediaOne] = useState(null);
  const [mediaTwo, setMediaTwo] = useState(null);
  const [mediaThree, setMediaThree] = useState(null);
  const [mediaFour, setMediaFour] = useState(null);
  const [internalPage, setInternalPage] = useState(false);

  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const { formProps, saveButtonProps } = useForm<IPost>();
  const [thumbnail, setThumbnail] = useState(null);

  const { selectProps } = useSelect({
    resource: "filters",
    // metaData: { filters },
    optionLabel: "componentLabel",
    optionValue: "id",
  });

  //   const { options } = useSelect({
  //     resource: "filters",
  //     // optionLabel: "title",
  //     // optionValue: "id",
  //   });

  const { ...uploadProps } = useStrapiUpload({
    maxCount: 1,
  });

  // console.log("API");
  // console.log(API_URL);

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinishFailed={() => {
          message.error("Submit failed!, Please check the input fields");
        }}
        onFinish={(values: any) => {
          const media = {
            mediaone: mediaOne,
            mediatwo: mediaTwo,
            mediathree: mediaThree,
            mediafour: mediaFour,
          };
          values["media"] = media;
          values["slug"] = values.pageTitle
            .replace(/[^a-zA-Z0-9 ]/g, "")
            .toLowerCase()
            .split(" ")
            .join("-");
          values["thumbnail"] = thumbnail;
          // values.haveInternalPage === undefined
          //   ? (values["haveInternalPage"] = false)
          //   : (values["haveInternalPage"] = values["haveInternalPage"]);
          values["haveInternalPage"] = internalPage;

          return (
            formProps.onFinish && formProps.onFinish(mediaUploadMapper(values))
          );
        }}
      >
        <Form.Item
          label="Page title"
          name="pageTitle"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Order"
          name="order"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item label="Thumbnail">
          <Form.Item
            name="thumbnail"
            valuePropName="fileList"
            getValueProps={(data) => getValueProps(data, API_URL)}
            noStyle
          >
            <Upload.Dragger
              name="files"
              action={`${API_URL}/upload`}
              listType="picture"
              multiple
              {...uploadProps}
              onChange={(info: any) => {
                if (info.file.status === "done") {
                  setThumbnail(info?.file?.response[0]?.id);
                }
              }}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        <Form.Item
          label="Have internal page?"
          name="haveInternalPage"
          valuePropName="checked"
        >
          <Switch
            onChange={(check) => {
              setInternalPage(check);
            }}
          />
        </Form.Item>

        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item>

        <Form.Item
          label="Filters"
          name="filters"
          rules={[
            {
              required: true,
            },
            // {
            //   validator: (rule, value, callback) => {
            //     if (value) {
            //       if (value.length > 5) {
            //         callback("No more than 5 filters");
            //       } else if (value.length <= 5) {
            //         callback();
            //       }
            //     }
            //     return;
            //   },
            // },
          ]}
        >
          <Select {...selectProps} mode="multiple" allowClear />
        </Form.Item>

        <Form.Item
          label="Component Category"
          name="componentCategory"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            <option value="Optics">Optics</option>
            <option value="Camera">Camera</option>
            {/* <option value="Software">Software</option> */}
            <option value="Accessories">Accessories</option>
            <option value="FrameGrabber">Frame Grabber</option>
          </Select>
        </Form.Item>

        <Form.Item label="Media One">
          <Form.Item
            name={["media", "mediaone"]}
            valuePropName="fileList"
            getValueProps={(data) => getValueProps(data, API_URL)}
            noStyle
          >
            <Upload.Dragger
              name="files"
              action={`${API_URL}/upload`}
              // headers={{
              //   Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
              // }}
              listType="picture"
              multiple
              {...uploadProps}
              onChange={(info: any) => {
                if (info.file.status === "done") {
                  setMediaOne(info?.file?.response[0]?.id);
                }
              }}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        <Form.Item label="Media Two">
          <Form.Item
            name={["media", "mediatwo"]}
            valuePropName="fileList"
            getValueProps={(data) => getValueProps(data, API_URL)}
            noStyle
          >
            <Upload.Dragger
              name="files"
              action={`${API_URL}/upload`}
              // headers={{
              //   Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
              // }}
              listType="picture"
              multiple
              {...uploadProps}
              onChange={(info: any) => {
                if (info.file.status === "done") {
                  setMediaTwo(info?.file?.response[0]?.id);
                }
              }}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        <Form.Item label="Media Three">
          <Form.Item
            name={["media", "mediathree"]}
            valuePropName="fileList"
            getValueProps={(data) => getValueProps(data, API_URL)}
            noStyle
          >
            <Upload.Dragger
              name="files"
              action={`${API_URL}/upload`}
              // headers={{
              //   Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
              // }}
              listType="picture"
              multiple
              {...uploadProps}
              onChange={(info: any) => {
                if (info.file.status === "done") {
                  setMediaThree(info?.file?.response[0]?.id);
                }
              }}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        <Form.Item label="Media Four">
          <Form.Item
            name={["media", "mediafour"]}
            valuePropName="fileList"
            getValueProps={(data) => getValueProps(data, API_URL)}
            noStyle
          >
            <Upload.Dragger
              name="files"
              action={`${API_URL}/upload`}
              // headers={{
              //   Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
              // }}
              listType="picture"
              multiple
              {...uploadProps}
              onChange={(info: any) => {
                if (info.file.status === "done") {
                  setMediaFour(info?.file?.response[0]?.id);
                }
              }}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        {/* ------------------------------- */}
      </Form>
    </Create>
  );
};
