import React, { useState } from "react";
import { IResourceComponentsProps, useApiUrl } from "@pankod/refine-core";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Create,
  Form,
  Input,
  Select,
  useForm,
  useSelect,
  Upload,
  Radio,
  Button,
  Space,
  message,
} from "@pankod/refine-antd";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import {
  useStrapiUpload,
  mediaUploadMapper,
  getValueProps,
} from "@pankod/refine-strapi-v4";
// import { TOKEN_KEY } from "../../constants";
import { IPost } from "interfaces";

export const SoftwareCreate: React.FC<IResourceComponentsProps> = () => {
  const [mediaOne, setMediaOne] = useState(null);
  const [mediaTwo, setMediaTwo] = useState(null);
  const [mediaThree, setMediaThree] = useState(null);
  const [mediaFour, setMediaFour] = useState(null);
  const [brochure, setBrochure] = useState(null);

  const [featuresImage, setFeaturesImage] = useState(null);
  const [newScope, setNewScope] = useState<any[]>([]);
  const [thumbnail, setThumbnail] = useState(null);

  const API_URL = useApiUrl();
  //   const [locale, setLocale] = useState("en");

  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const { formProps, saveButtonProps } = useForm<IPost>();

  const { selectProps } = useSelect({
    resource: "filters",
    // metaData: { filters },
    optionLabel: "name",
    optionValue: "id",
  });

  //   const { options } = useSelect({
  //     resource: "filters",
  //     // optionLabel: "title",
  //     // optionValue: "id",
  //   });

  const { ...uploadProps } = useStrapiUpload({
    maxCount: 1,
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinishFailed={() => {
          message.error("Submit failed!, Please check the input fields");
        }}
        onFinish={async (values: any) => {
          const media = {
            mediaone: mediaOne,
            mediatwo: mediaTwo,
            mediathree: mediaThree,
            mediafour: mediaFour,
          };
          values["mainbanner"]["media"] = media;
          values["mainbanner"]["brochure"] = brochure;
          values["thumbnail"] = thumbnail;
          values["latestFeatures"]["image"] = featuresImage;
          values["slug"] = values.pageTitle
            .replace(/[^a-zA-Z0-9 ]/g, "")
            .toLowerCase()
            .split(" ")
            .join("-");

          //   let newScopeArray: any[] = [];
          //   values?.scope?.scope?.map((value: any) => {
          //     newScopeArray.push({
          //       title: value?.title,
          //       image: value?.image?.file?.response[0]?.id,
          //     });
          //   });

          //   values["scope"]["scope"] = newScopeArray;

          return (
            formProps.onFinish && formProps.onFinish(mediaUploadMapper(values))
          );
        }}
      >
        <Form.Item
          label="Page title"
          name="pageTitle"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Order"
          name="order"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item label="Thumbnail">
          <Form.Item
            name="thumbnail"
            valuePropName="fileList"
            getValueProps={(data) => getValueProps(data, API_URL)}
            noStyle
          >
            <Upload.Dragger
              name="files"
              action={`${API_URL}/upload`}
              listType="picture"
              multiple
              showUploadList={{ showRemoveIcon: false }}
              {...uploadProps}
              onChange={(info: any) => {
                if (info.file.status === "done") {
                  setThumbnail(info?.file?.response[0]?.id);
                }
              }}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        <Form.Item
          label="Card Description"
          name="description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item>
        {/* ----------------- main banner --------------------- */}
        <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>Main Banner</h1>

        <Form.Item
          label="Heading"
          name={["mainbanner", "heading"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Description"
          name={["mainbanner", "description"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item>

        <Form.Item label="Media One">
          <Form.Item
            name="mediaone"
            valuePropName="fileList"
            getValueProps={(data) => getValueProps(data, API_URL)}
            noStyle
          >
            <Upload.Dragger
              name="files"
              action={`${API_URL}/upload`}
              listType="picture"
              multiple
              showUploadList={{ showRemoveIcon: false }}
              {...uploadProps}
              onChange={(info: any) => {
                if (info.file.status === "done") {
                  setMediaOne(info?.file?.response[0]?.id);
                }
              }}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        <Form.Item label="Media Two">
          <Form.Item
            name="mediatwo"
            valuePropName="fileList"
            getValueProps={(data) => getValueProps(data, API_URL)}
            noStyle
          >
            <Upload.Dragger
              name="files"
              action={`${API_URL}/upload`}
              listType="picture"
              multiple
              showUploadList={{ showRemoveIcon: false }}
              {...uploadProps}
              onChange={(info: any) => {
                if (info.file.status === "done") {
                  setMediaTwo(info?.file?.response[0]?.id);
                }
              }}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        <Form.Item label="Media Three">
          <Form.Item
            name="mediathree"
            valuePropName="fileList"
            getValueProps={(data) => getValueProps(data, API_URL)}
            noStyle
          >
            <Upload.Dragger
              name="files"
              action={`${API_URL}/upload`}
              listType="picture"
              multiple
              showUploadList={{ showRemoveIcon: false }}
              {...uploadProps}
              onChange={(info: any) => {
                if (info.file.status === "done") {
                  setMediaThree(info?.file?.response[0]?.id);
                }
              }}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        <Form.Item label="Brochure">
          <Form.Item
            name="brochure"
            valuePropName="fileList"
            getValueProps={(data) => getValueProps(data, API_URL)}
            noStyle
          >
            <Upload.Dragger
              name="files"
              action={`${API_URL}/upload`}
              listType="picture"
              multiple
              {...uploadProps}
              beforeUpload={(file) => {
                const isPDF = file.type === "application/pdf";
                if (!isPDF) {
                  message.error(`${file.name} is not a pdf file`);
                }
                return isPDF || Upload.LIST_IGNORE;
              }}
              onChange={(info: any) => {
                if (info.file.status === "done") {
                  setBrochure(info?.file?.response[0]?.id);
                }
              }}
            >
              <p className="ant-upload-text">
                Drag & drop a PDF file in this area
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        {/* ------------------------------------------------------- */}
        {/* --------------- latest version features ------------------------ */}

        <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
          Latest features section
        </h1>

        <Form.Item
          label="Heading"
          name={["latestFeatures", "title"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Description"
          name={["latestFeatures", "description"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item>

        <Form.Item label="Image">
          <Form.Item
            name="image"
            valuePropName="fileList"
            getValueProps={(data) => getValueProps(data, API_URL)}
            noStyle
          >
            <Upload.Dragger
              name="files"
              action={`${API_URL}/upload`}
              listType="picture"
              multiple
              showUploadList={{ showRemoveIcon: false }}
              {...uploadProps}
              onChange={(info: any) => {
                if (info.file.status === "done") {
                  setFeaturesImage(info?.file?.response[0]?.id);
                }
              }}
            >
              <p className="ant-upload-text">
                Drag & drop an image in this area
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        {/* ------------------------------------------- */}
        {/* ------------- highlights ------------------ */}

        <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
          Highlights section
        </h1>

        <Form.Item
          label="Title"
          name={["highlights", "title"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Description"
          name={["highlights", "description"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item>

        {/* --------------------------------------------- */}
        {/* ----------------- features --------------------- */}

        <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
          Features section
        </h1>

        <Form.Item
          label="Title"
          name={["features", "title"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Description"
          name={["features", "description"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item>

        {/* ------------------------------------------- */}
        {/* ----------------- try section ------------- */}

        <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
          Software try section
        </h1>

        <Form.Item
          label="Heading"
          name={["softwaretry", "heading"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Video Heading"
          name={["softwaretry", "videoheading"]}
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Video Link" name={["softwaretry", "videolink"]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Description"
          name={["softwaretry", "description"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item>

        {/* ------------------------------------------------------ */}
      </Form>
    </Create>
  );
};
