import { useShow } from "@pankod/refine-core";
import { Show, Typography } from "@pankod/refine-antd";

const { Title, Text } = Typography;

export const FilterShow = () => {
  const { queryResult } = useShow({
    metaData: {
      populate: {
        values: {
          populate: "*",
        },
      },
    },
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Label</Title>
      <Text>{record?.label}</Text>

      <Title level={5}>Field Name</Title>
      <Text>{record?.fieldName}</Text>

      <Title level={5}>Values</Title>
      <Text>{record?.mainbanner?.heading}</Text>
      <ul>
        {record?.values?.map((value: any) => {
          return (
            <li>
              <Text>{value?.value}</Text>
            </li>
          );
        })}
      </ul>
    </Show>
  );
};
