import React, { useEffect, useState } from "react";
import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Edit,
  Form,
  Input,
  Select,
  useForm,
  useSelect,
  Upload,
  Radio,
  Button,
  Space,
  PageHeader,
  message,
} from "@pankod/refine-antd";
import {
  useStrapiUpload,
  getValueProps,
  mediaUploadMapper,
} from "@pankod/refine-strapi-v4";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";

// import { TOKEN_KEY, API_URL } from "../../constants";
import { ICategory, IPost } from "interfaces";
import { serverUrl } from "server";
import ErrorBoundary from "components/errors/ErrorBoundary";

export const SoftwareEdit: React.FC<IResourceComponentsProps> = () => {
  const [mediaOne, setMediaOne] = useState(null);
  const [mediaTwo, setMediaTwo] = useState(null);
  const [mediaThree, setMediaThree] = useState(null);
  const [mediaFour, setMediaFour] = useState(null);
  const [brochure, setBrochure] = useState(null);
  const [featuresImage, setFeaturesImage] = useState(null);
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const [newScope, setNewScope] = useState<any[]>([]);
  const [thumbnail, setThumbnail] = useState(null);

  const [form] = Form.useForm();

  const { formProps, saveButtonProps } = useForm<IPost>({
    metaData: { populate: "*" },
  });

  const { queryResult } = useShow({
    metaData: {
      populate: "deep",
    },
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  //   const { selectProps } = useSelect<ICategory>({
  //     resource: "categories",
  //     defaultValue: queryResult?.data?.data?.category?.id,
  //     // metaData: { locale: queryResult?.data?.data.locale },
  //   });

  const { ...uploadProps } = useStrapiUpload({
    maxCount: 1,
  });

  useEffect(() => {
    if (record) {
      form.resetFields();
      if (record?.mainbanner?.media?.mediaone) {
        form.setFieldsValue({
          mediaone: [record?.mainbanner?.media?.mediaone],
        });
        setMediaOne(record?.mainbanner?.media?.mediaone?.id);
      }
      if (record?.mainbanner?.media?.mediatwo) {
        form.setFieldsValue({
          mediatwo: [record?.mainbanner?.media?.mediatwo],
        });
        setMediaTwo(record?.mainbanner?.media?.mediatwo?.id);
      }
      if (record?.mainbanner?.media?.mediathree) {
        form.setFieldsValue({
          mediathree: [record?.mainbanner?.media?.mediathree],
        });
        setMediaThree(record?.mainbanner?.media?.mediathree?.id);
      }
      if (record?.mainbanner?.media?.mediafour) {
        form.setFieldsValue({
          mediafour: [record?.mainbanner?.media?.mediafour],
        });
        setMediaFour(record?.mainbanner?.media?.mediafour?.id);
      }
      if (record?.mainbanner?.brochure) {
        form.setFieldsValue({
          brochure: [record?.mainbanner?.brochure],
        });
        setBrochure(record?.mainbanner?.brochure?.id);
      }
      if (record?.latestFeatures?.image) {
        form.setFieldsValue({
          image: [record?.latestFeatures?.image],
        });
        setFeaturesImage(record?.latestFeatures?.image?.id);
      }
      if (record?.thumbnail) {
        form.setFieldsValue({
          thumbnail: [record?.thumbnail],
        });
        setThumbnail(record?.thumbnail?.id);
      }
      //
    }
  }, [record]);

  const routes = [
    {
      path: "",
      breadcrumbName: "Softwares",
    },
    {
      path: "",
      breadcrumbName: "Edit",
    },
  ];

  return (
    // <Edit saveButtonProps={saveButtonProps}>
    <div
      style={{
        // border: "1px solid red",
        backgroundColor: "white",
        borderRadius: "6px",
      }}
    >
      <PageHeader
        className="site-page-header"
        title="Edit softwares"
        onBack={() => window.history.back()}
        breadcrumb={{
          routes,
        }}
      >
        <ErrorBoundary>
          <Form
            {...formProps}
            layout="vertical"
            form={form}
            // eslint-disable-next-line
            initialValues={{
              ...record,
              thumbnail: record?.thumbnail?.data?.id,
            }}
            onFinishFailed={() => {
              message.error("Submit failed!, Please check the input fields");
            }}
            onFinish={(values: any) => {
              const media = {
                mediaone: mediaOne,
                mediatwo: mediaTwo,
                mediathree: mediaThree,
                mediafour: mediaFour,
              };
              values["thumbnail"] = thumbnail;
              values["mainbanner"]["media"] = media;
              values["mainbanner"]["brochure"] = brochure;
              values["latestFeatures"]["image"] = featuresImage;
              values["slug"] = values.pageTitle
                .replace(/[^a-zA-Z0-9 ]/g, "")
                .toLowerCase()
                .split(" ")
                .join("-");

              return formProps.onFinish?.(mediaUploadMapper(values));
            }}
          >
            <Form.Item
              label="Page title"
              name="pageTitle"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Order"
              name="order"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>

            <Form.Item label="Thumbnail">
              <Form.Item
                name="thumbnail"
                valuePropName="fileList"
                getValueProps={(data) => getValueProps(data, serverUrl)}
                noStyle
              >
                <Upload.Dragger
                  name="files"
                  action={`${serverUrl}/api/upload`}
                  listType="picture"
                  multiple
                  showUploadList={{ showRemoveIcon: false }}
                  {...uploadProps}
                  onChange={(info: any) => {
                    if (info.file.status === "done") {
                      setThumbnail(info?.file?.response[0]?.id);
                    }
                  }}
                >
                  <p className="ant-upload-text">
                    Drag & drop a file in this area
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>

            <Form.Item
              label="Card Description"
              name="description"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <ReactMde
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={(markdown) =>
                  Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                }
              />
            </Form.Item>
            {/* ----------------- main banner --------------------- */}
            <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
              Main Banner
            </h1>

            <Form.Item
              label="Heading"
              name={["mainbanner", "heading"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Description"
              name={["mainbanner", "description"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <ReactMde
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={(markdown) =>
                  Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                }
              />
            </Form.Item>

            <Form.Item label="Media One">
              <Form.Item
                name="mediaone"
                valuePropName="fileList"
                getValueProps={(data) => getValueProps(data, serverUrl)}
                noStyle
              >
                <Upload.Dragger
                  name="files"
                  action={`${serverUrl}/api/upload`}
                  listType="picture"
                  multiple
                  showUploadList={{ showRemoveIcon: false }}
                  {...uploadProps}
                  onChange={(info: any) => {
                    if (info.file.status === "done") {
                      setMediaOne(info?.file?.response[0]?.id);
                    }
                  }}
                >
                  <p className="ant-upload-text">
                    Drag & drop a file in this area
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>

            <Form.Item label="Media Two">
              <Form.Item
                name="mediatwo"
                valuePropName="fileList"
                getValueProps={(data) => getValueProps(data, serverUrl)}
                noStyle
              >
                <Upload.Dragger
                  name="files"
                  action={`${serverUrl}/api/upload`}
                  listType="picture"
                  multiple
                  showUploadList={{ showRemoveIcon: false }}
                  {...uploadProps}
                  onChange={(info: any) => {
                    if (info.file.status === "done") {
                      setMediaTwo(info?.file?.response[0]?.id);
                    }
                  }}
                >
                  <p className="ant-upload-text">
                    Drag & drop a file in this area
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>

            <Form.Item label="Media Three">
              <Form.Item
                name="mediathree"
                valuePropName="fileList"
                getValueProps={(data) => getValueProps(data, serverUrl)}
                noStyle
              >
                <Upload.Dragger
                  name="files"
                  action={`${serverUrl}/api/upload`}
                  listType="picture"
                  multiple
                  showUploadList={{ showRemoveIcon: false }}
                  {...uploadProps}
                  onChange={(info: any) => {
                    if (info.file.status === "done") {
                      setMediaThree(info?.file?.response[0]?.id);
                    }
                  }}
                >
                  <p className="ant-upload-text">
                    Drag & drop a file in this area
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>

            <Form.Item label="Brochure">
              <Form.Item
                name="brochure"
                valuePropName="fileList"
                getValueProps={(data) => getValueProps(data, serverUrl)}
                noStyle
              >
                <Upload.Dragger
                  name="files"
                  action={`${serverUrl}/api/upload`}
                  listType="picture"
                  multiple
                  showUploadList={{ showRemoveIcon: false }}
                  {...uploadProps}
                  beforeUpload={(file) => {
                    const isPDF = file.type === "application/pdf";
                    if (!isPDF) {
                      message.error(`${file.name} is not a pdf file`);
                    }
                    return isPDF || Upload.LIST_IGNORE;
                  }}
                  onChange={(info: any) => {
                    if (info.file.status === "done") {
                      setBrochure(info?.file?.response[0]?.id);
                    }
                  }}
                >
                  <p className="ant-upload-text">
                    Drag & drop a PDF file in this area
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>

            {/* ------------------------------------------------------- */}
            {/* --------------- latest version features ------------------------ */}

            <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
              Latest features section
            </h1>

            <Form.Item
              label="Heading"
              name={["latestFeatures", "title"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Description"
              name={["latestFeatures", "description"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <ReactMde
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={(markdown) =>
                  Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                }
              />
            </Form.Item>

            <Form.Item label="Image">
              <Form.Item
                name="image"
                valuePropName="fileList"
                getValueProps={(data) => getValueProps(data, serverUrl)}
                noStyle
              >
                <Upload.Dragger
                  name="files"
                  action={`${serverUrl}/api/upload`}
                  listType="picture"
                  multiple
                  showUploadList={{ showRemoveIcon: false }}
                  {...uploadProps}
                  onChange={(info: any) => {
                    if (info.file.status === "done") {
                      setFeaturesImage(info?.file?.response[0]?.id);
                    }
                  }}
                >
                  <p className="ant-upload-text">
                    Drag & drop an image in this area
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>

            {/* ------------------------------------------- */}
            {/* ------------- highlights ------------------ */}

            <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
              Highlights section
            </h1>

            <Form.Item
              label="Title"
              name={["highlights", "title"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Description"
              name={["highlights", "description"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <ReactMde
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={(markdown) =>
                  Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                }
              />
            </Form.Item>

            {/* --------------------------------------------- */}
            {/* ----------------- features --------------------- */}

            <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
              Features section
            </h1>

            <Form.Item
              label="Title"
              name={["features", "title"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Description"
              name={["features", "description"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <ReactMde
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={(markdown) =>
                  Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                }
              />
            </Form.Item>

            {/* ----------------- try section ------------- */}

            <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
              Software try section
            </h1>

            <Form.Item
              label="Heading"
              name={["softwaretry", "heading"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Video Heading"
              name={["softwaretry", "videoheading"]}
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Video Link" name={["softwaretry", "videolink"]}>
              <Input />
            </Form.Item>

            <Form.Item
              label="Description"
              name={["softwaretry", "description"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <ReactMde
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={(markdown) =>
                  Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                }
              />
            </Form.Item>

            {/* ------------------------------------------------------ */}

            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Button type="primary" onClick={() => form.submit()}>
                Save
              </Button>
            </div>
            {/* ------------------------------------------- */}
          </Form>
        </ErrorBoundary>
      </PageHeader>
    </div>
    // </Edit>
  );
};
