import { useState } from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";

import {
  List,
  Table,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  Form,
  ShowButton,
} from "@pankod/refine-antd";

import { IPost } from "interfaces";

export const ComponentList: React.FC<IResourceComponentsProps> = () => {
  //   const [locale, setLocale] = useState("en");
  const [publicationState, setPublicationState] = useState("live");

  const { tableProps, sorter } = useTable<IPost>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    metaData: {
      populate: ["products"],
      publicationState,
    },
  });

  return (
    <List>
      <Form
        layout="inline"
        initialValues={{
          publicationState,
        }}
      ></Form>
      <br />
      <Table
        {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
        }}
      >
        <Table.Column
          key="name"
          dataIndex="name"
          title="Name"
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter={{ multiple: 2 }}
        />
        <Table.Column
          dataIndex="componentCategory"
          key="componentCategory"
          title="Component Category"
          defaultSortOrder={getDefaultSortOrder("componentCategory", sorter)}
          sorter={{ multiple: 2 }}
        />

        <Table.Column
          dataIndex="createdAt"
          title="Created At"
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter={{ multiple: 1 }}
        />

        <Table.Column<{ id: string }>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
              <ShowButton size="small" recordItemId={record.id} hideText />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
