// import "./style.less";
// import { BikeWhiteIcon } from "components";

type TitleProps = {
  collapsed: boolean;
};

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
  return (
    <div className="logo">
      {collapsed ? (
        <img
          src="/images/lucid-icon.png"
          alt="Lucid"
          style={{ width: "100%", height: "50px" }}
        />
      ) : (
        <img
          src="/images/lucid-logo.svg"
          alt="Lucid"
          style={{ width: "100%", height: "50px", objectFit: "cover" }}
        />
      )}
    </div>
  );
};
