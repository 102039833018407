import React, { useEffect, useState } from "react";
import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Select,
  useForm,
  Upload,
  Button,
  Space,
  PageHeader,
  message,
} from "@pankod/refine-antd";
import {
  useStrapiUpload,
  getValueProps,
  mediaUploadMapper,
} from "@pankod/refine-strapi-v4";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";

import { IPost } from "interfaces";
import { serverUrl } from "server";
import ErrorBoundary from "components/errors/ErrorBoundary";

export const SystemEdit: React.FC<IResourceComponentsProps> = () => {
  const [mediaOne, setMediaOne] = useState(null);
  const [mediaTwo, setMediaTwo] = useState(null);
  const [mediaThree, setMediaThree] = useState(null);
  const [mediaFour, setMediaFour] = useState(null);
  const [brochure, setBrochure] = useState(null);

  const [highlightImage, setHighlightImage] = useState(null);
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const [newScope, setNewScope] = useState<any[]>([]);
  const [scopeImageVisible, setScopeImageVisible] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [form] = Form.useForm();

  const { formProps, saveButtonProps } = useForm<IPost>({
    metaData: { populate: "*" },
  });

  const { queryResult } = useShow({
    metaData: {
      populate: "deep",
    },
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { ...uploadProps } = useStrapiUpload({
    maxCount: 1,
  });

  useEffect(() => {
    if (record) {
      form.resetFields();
      if (record?.mainbanner?.media?.mediaone) {
        form.setFieldsValue({
          mediaone: [record?.mainbanner?.media?.mediaone],
        });
        setMediaOne(record?.mainbanner?.media?.mediaone?.id);
      }
      if (record?.mainbanner?.media?.mediatwo) {
        form.setFieldsValue({
          mediatwo: [record?.mainbanner?.media?.mediatwo],
        });
        setMediaTwo(record?.mainbanner?.media?.mediatwo?.id);
      }
      if (record?.mainbanner?.media?.mediathree) {
        form.setFieldsValue({
          mediathree: [record?.mainbanner?.media?.mediathree],
        });
        setMediaThree(record?.mainbanner?.media?.mediathree?.id);
      }
      if (record?.mainbanner?.media?.mediafour) {
        form.setFieldsValue({
          mediafour: [record?.mainbanner?.media?.mediafour],
        });
        setMediaFour(record?.mainbanner?.media?.mediafour?.id);
      }
      if (record?.mainbanner?.brochure) {
        form.setFieldsValue({
          brochure: [record?.mainbanner?.brochure],
        });
        setBrochure(record?.mainbanner?.brochure?.id);
      }
      if (record?.highlights?.image) {
        form.setFieldsValue({
          image: [record?.highlights?.image],
        });
        setHighlightImage(record?.highlights?.image?.id);
      }
      if (record?.thumbnail) {
        form.setFieldsValue({
          thumbnail: [record?.thumbnail],
        });
        setThumbnail(record?.thumbnail?.id);
      }
      if (record?.scope?.scope) {
        let newScopeArray: any[] = [];
        // let newScopeArray2: any[] = [];

        record?.scope?.scope?.map((value: any) => {
          newScopeArray.push({
            title: value?.title,
            image: [value?.image],
          });
        });

        setNewScope(newScopeArray);

        form.setFieldsValue({
          scope: { scope: newScopeArray },
        });
      }
    }
  }, [record]);

  const routes = [
    {
      path: "",
      breadcrumbName: "Systems",
    },
    {
      path: "",
      breadcrumbName: "Edit",
    },
  ];

  useEffect(() => {
    let timer = setTimeout(() => setScopeImageVisible(true), 500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    // <Edit saveButtonProps={saveButtonProps}>
    <ErrorBoundary>
      <div
        style={{
          // border: "1px solid red",
          backgroundColor: "white",
          borderRadius: "6px",
        }}
      >
        <PageHeader
          className="site-page-header"
          title="Edit systems"
          onBack={() => window.history.back()}
          breadcrumb={{
            routes,
          }}
        >
          {record && (
            <Form
              {...formProps}
              layout="vertical"
              form={form}
              scrollToFirstError={true}
              // eslint-disable-next-line
              initialValues={{
                ...record,
                thumbnail: record?.thumbnail?.data?.id,
              }}
              onFinishFailed={() => {
                message.error("Submit failed!, Please check the input fields");
              }}
              onFinish={(values: any) => {
                const media = {
                  mediaone: mediaOne,
                  mediatwo: mediaTwo,
                  mediathree: mediaThree,
                  mediafour: mediaFour,
                };
                values["thumbnail"] = thumbnail;
                values["mainbanner"]["media"] = media;
                values["mainbanner"]["brochure"] = brochure;

                values["highlights"]["image"] = highlightImage;
                values["slug"] = values?.pageTitle
                  .replace(/[^a-zA-Z0-9 ]/g, "")
                  .toLowerCase()
                  .split(" ")
                  .join("-");
                // let newScopeArray: any[] = [];
                // values?.scope?.scope?.map((value: any) => {
                //   newScopeArray.push({
                //     title: value?.title,
                //     image: value?.image?.file?.response[0]?.id,
                //   });
                // });

                values["scope"]["scope"] = newScope;

                return formProps.onFinish?.(mediaUploadMapper(values));
              }}
              // onFinish={onFinish}
            >
              <Form.Item
                label="Page title"
                name="pageTitle"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Order"
                name="order"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>

              <Form.Item label="Thumbnail">
                <Form.Item
                  name="thumbnail"
                  valuePropName="fileList"
                  getValueProps={(data) => getValueProps(data, serverUrl)}
                  noStyle
                >
                  <Upload.Dragger
                    name="files"
                    action={`${serverUrl}/api/upload`}
                    listType="picture"
                    multiple
                    showUploadList={{ showRemoveIcon: false }}
                    {...uploadProps}
                    onChange={(info: any) => {
                      if (info?.file?.status === "done") {
                        setThumbnail(info?.file?.response[0]?.id);
                      }
                    }}
                  >
                    <p className="ant-upload-text">
                      Drag & drop a file in this area
                    </p>
                  </Upload.Dragger>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label="Card Description"
                name="description"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <ReactMde
                  selectedTab={selectedTab}
                  onTabChange={setSelectedTab}
                  generateMarkdownPreview={(markdown) =>
                    Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                  }
                />
              </Form.Item>

              <Form.Item
                label="System Category"
                name="systemCategory"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select>
                  <Select.Option value="Security">Security</Select.Option>
                  <Select.Option value="Commercial">Commercial</Select.Option>
                </Select>
              </Form.Item>

              {/* ----------------- main banner --------------------- */}
              <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
                Main Banner
              </h1>

              <Form.Item
                label="Heading"
                name={["mainbanner", "heading"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Description"
                name={["mainbanner", "description"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <ReactMde
                  selectedTab={selectedTab}
                  onTabChange={setSelectedTab}
                  generateMarkdownPreview={(markdown) =>
                    Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                  }
                />
              </Form.Item>

              <Form.Item label="Media One">
                <Form.Item
                  name="mediaone"
                  valuePropName="fileList"
                  getValueProps={(data) => getValueProps(data, serverUrl)}
                  noStyle
                >
                  <Upload.Dragger
                    name="files"
                    action={`${serverUrl}/api/upload`}
                    listType="picture"
                    multiple
                    showUploadList={{ showRemoveIcon: false }}
                    {...uploadProps}
                    onChange={(info: any) => {
                      if (info.file.status === "done") {
                        setMediaOne(info?.file?.response[0]?.id);
                      }
                    }}
                  >
                    <p className="ant-upload-text">
                      Drag & drop a file in this area
                    </p>
                  </Upload.Dragger>
                </Form.Item>
              </Form.Item>

              <Form.Item label="Media Two">
                <Form.Item
                  name="mediatwo"
                  valuePropName="fileList"
                  getValueProps={(data) => getValueProps(data, serverUrl)}
                  noStyle
                >
                  <Upload.Dragger
                    name="files"
                    action={`${serverUrl}/api/upload`}
                    listType="picture"
                    multiple
                    showUploadList={{ showRemoveIcon: false }}
                    {...uploadProps}
                    onChange={(info: any) => {
                      if (info.file.status === "done") {
                        setMediaTwo(info?.file?.response[0]?.id);
                      }
                    }}
                  >
                    <p className="ant-upload-text">
                      Drag & drop a file in this area
                    </p>
                  </Upload.Dragger>
                </Form.Item>
              </Form.Item>

              <Form.Item label="Media Three">
                <Form.Item
                  name="mediathree"
                  valuePropName="fileList"
                  getValueProps={(data) => getValueProps(data, serverUrl)}
                  noStyle
                >
                  <Upload.Dragger
                    name="files"
                    action={`${serverUrl}/api/upload`}
                    listType="picture"
                    multiple
                    showUploadList={{ showRemoveIcon: false }}
                    {...uploadProps}
                    onChange={(info: any) => {
                      if (info.file.status === "done") {
                        setMediaThree(info?.file?.response[0]?.id);
                      }
                    }}
                  >
                    <p className="ant-upload-text">
                      Drag & drop a file in this area
                    </p>
                  </Upload.Dragger>
                </Form.Item>
              </Form.Item>

              {/* <Form.Item label="Media Four">
              <Form.Item
                name="mediafour"
                valuePropName="fileList"
                getValueProps={(data) => getValueProps(data, serverUrl)}
                noStyle
              >
                <Upload.Dragger
                  name="files"
                  action={`${serverUrl}/api/upload`}
                  listType="picture"
                  multiple
                  {...uploadProps}
                  onChange={(info: any) => {
                    if (info?.file?.status === "done") {
                      setMediaFour(info?.file?.response[0]?.id);
                    }
                  }}
                >
                  <p className="ant-upload-text">
                    Drag & drop a file in this area
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item> */}

              <Form.Item label="Media Three">
                <Form.Item
                  name="mediathree"
                  valuePropName="fileList"
                  getValueProps={(data) => getValueProps(data, serverUrl)}
                  noStyle
                >
                  <Upload.Dragger
                    name="files"
                    action={`${serverUrl}/api/upload`}
                    listType="picture"
                    multiple
                    showUploadList={{ showRemoveIcon: false }}
                    {...uploadProps}
                    onChange={(info: any) => {
                      if (info.file.status === "done") {
                        setMediaThree(info?.file?.response[0]?.id);
                      }
                    }}
                  >
                    <p className="ant-upload-text">
                      Drag & drop a file in this area
                    </p>
                  </Upload.Dragger>
                </Form.Item>
              </Form.Item>

              <Form.Item label="Brochure">
                <Form.Item
                  name="brochure"
                  valuePropName="fileList"
                  getValueProps={(data) => getValueProps(data, serverUrl)}
                  noStyle
                >
                  <Upload.Dragger
                    name="files"
                    action={`${serverUrl}/api/upload`}
                    listType="picture"
                    multiple
                    showUploadList={{ showRemoveIcon: false }}
                    {...uploadProps}
                    // onChange={(info: any) => {
                    //   if (info.file.status === "done") {
                    //     setBrochure(info?.file?.response[0]?.id);
                    //   }
                    // }}
                    beforeUpload={(file) => {
                      const isPDF = file.type === "application/pdf";
                      if (!isPDF) {
                        message.error(`${file.name} is not a pdf file`);
                      }
                      return isPDF || Upload.LIST_IGNORE;
                    }}
                    onChange={(info: any) => {
                      if (info.file.status === "done") {
                        setBrochure(info?.file?.response[0]?.id);
                      }
                    }}
                  >
                    <p className="ant-upload-text">
                      Drag & drop a PDF file in this area
                    </p>
                  </Upload.Dragger>
                </Form.Item>
              </Form.Item>

              {/* ------------------------------------------------------- */}
              {/* --------------- specifications ------------------------ */}

              <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
                Specification section
              </h1>

              <Form.Item
                label="Heading"
                name={["specifications", "heading"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.List name={["specifications", "specification"]}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={
                          {
                            // display: "flex",
                            // marginBottom: 8,
                          }
                        }
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "title"]}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input placeholder="Title" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "description"]}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <ReactMde
                            selectedTab={selectedTab}
                            onTabChange={setSelectedTab}
                            generateMarkdownPreview={(markdown) =>
                              Promise.resolve(
                                <ReactMarkdown>{markdown}</ReactMarkdown>
                              )
                            }
                          />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Specification
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              {/* ------------------------------------------- */}
              {/* ------------- highlights ------------------ */}

              <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
                Highlights section
              </h1>

              <Form.Item
                label="Title"
                name={["highlights", "title"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Description"
                name={["highlights", "description"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <ReactMde
                  selectedTab={selectedTab}
                  onTabChange={setSelectedTab}
                  generateMarkdownPreview={(markdown) =>
                    Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                  }
                />
              </Form.Item>

              <Form.Item label="Image">
                <Form.Item
                  name="image"
                  valuePropName="fileList"
                  getValueProps={(data) => getValueProps(data, serverUrl)}
                  noStyle
                >
                  <Upload.Dragger
                    name="files"
                    action={`${serverUrl}/api/upload`}
                    listType="picture"
                    multiple
                    showUploadList={{ showRemoveIcon: false }}
                    {...uploadProps}
                    onChange={(info: any) => {
                      if (info?.file?.status === "done") {
                        setHighlightImage(info?.file?.response[0]?.id);
                      }
                    }}
                  >
                    <p className="ant-upload-text">
                      Drag & drop a file in this area
                    </p>
                  </Upload.Dragger>
                </Form.Item>
              </Form.Item>

              {/* --------------------------------------------- */}
              {/* ----------------- scope --------------------- */}

              <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>
                Scope section
              </h1>

              <Form.Item
                label="Heading"
                name={["scope", "heading"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.List name={["scope", "scope"]}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space key={key} align="baseline">
                        <Form.Item
                          {...restField}
                          name={[name, "title"]}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input
                            placeholder="Title"
                            onChange={(e) => {
                              let newScopeArray = [...newScope];
                              newScopeArray[name] = {
                                ...newScopeArray[name],
                                title: e?.target?.value,
                              };
                              setNewScope(newScopeArray);
                            }}
                          />
                        </Form.Item>

                        {scopeImageVisible && (
                          <Form.Item style={{ width: "400px" }}>
                            <Form.Item
                              name={[name, "image"]}
                              valuePropName="fileList"
                              getValueProps={(data) =>
                                getValueProps(data, serverUrl)
                              }
                              noStyle
                            >
                              <Upload.Dragger
                                name="files"
                                action={`${serverUrl}/api/upload`}
                                listType="picture"
                                multiple
                                {...uploadProps}
                                onChange={(info: any) => {
                                  if (info.file.status === "done") {
                                    let newScopeArray = [...newScope];
                                    newScopeArray[name] = {
                                      ...newScopeArray[name],
                                      image: info?.file?.response[0]?.id,
                                    };
                                    setNewScope(newScopeArray);
                                  }
                                }}
                              >
                                <p className="ant-upload-text">
                                  Drag & drop an image
                                </p>
                              </Upload.Dragger>
                            </Form.Item>
                          </Form.Item>
                        )}

                        <MinusCircleOutlined
                          onClick={() => {
                            remove(name);
                            let newScopeArray = [...newScope];

                            newScopeArray.splice(name, 1);

                            setNewScope(newScopeArray);
                          }}
                        />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Scope
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Button type="primary" onClick={() => form.submit()}>
                  Save
                </Button>
              </div>
              {/* ------------------------------------------- */}
            </Form>
          )}
        </PageHeader>
      </div>
    </ErrorBoundary>
    // </Edit>
  );
};
